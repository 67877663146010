import React from 'react';

export class Text extends React.Component<{ elementType?:string, spec: Array<string|String>|string|String, id:number|string, className:string|null }, { }> {
    renderText(elementType: string, spec: Array<string|String>|string|String, id:number|string): React.ReactElement {
        if(Array.isArray(spec)) {
            return React.createElement(
                elementType, 
                { key: id },
                spec.map((item:any, index:number) => this.renderText('p', item, index))
            );
        }
        return React.createElement(elementType, {
            dangerouslySetInnerHTML: { __html: spec},
            key: id,
            className: this.props.className
        });  
    }

    render() {
        return this.renderText(this.props.elementType || 'span', this.props.spec, this.props.id || 1);
    }
}
