import React from 'react';
import { Label, Affixes } from './Conversation';
import { Dynamic } from './Dynamic';
import { RenderProps } from './RenderProps';
import Domain from './Domain'

const buttonStyle = { 
  marginTop: '16px' 
};

class Fixed extends React.Component<RenderProps, { }> {
  render() {
    const stack = this.props.stack;
    const spec = stack[stack.length - 1];
    return React.createElement(Dynamic, {
      ...this.props,
      key: this.props.id,
      stack: [ 
        ...this.props.stack, {
          key: spec['name'],
          '@class': 'Symbol.State',
          ...spec.fixed,
          ...spec.abbreviate_cue && { abbreviate_cue: spec.abbreviate_cue }
        }
      ]
    }, {});
  }
}

class AsFormSections extends React.Component<RenderProps, { }> {
  render() {
    const lang = this.props.lang || 'en_US';
    const stack = this.props.stack;
    const spec = stack[stack.length - 1];
    const label = React.createElement(Label, {
      lang, id:1, stack, classPrefix: 'symbol'
    });

    const inner:any = 
      spec.hidden ? [] 
      : spec.fixed ? [ React.createElement(Fixed, {
        ...this.props,
        id: 'inner',
        key: 'inner',
        stack: [...this.props.stack, spec.fixed ],
        mode: spec.iterator_mode
      }) ] : spec.subject ? [ React.createElement(Dynamic, {
        ...this.props,
        id: 'inner',
        key: 'inner',
        stack: [ ...this.props.stack, spec.subject ],
        mode: spec.iterator_mode,
        container: undefined
      }) ] :
      spec.domain ? [ React.createElement(Domain.AsFormSections, {
        ...this.props,
        id: 'inner',
        key: 'inner',
        stack: [ ...this.props.stack, spec.domain ],
        mode: spec.iterator_mode
      }) ] : null;

    return React.createElement(Affixes, {
      ...this.props,
      key: this.props.id
    }, inner);
  }
}

class AsFormItems extends React.Component<RenderProps, { }> {
  render() {
    const lang = this.props.lang || 'en_US';
    const stack = this.props.stack;
    const spec = stack[stack.length - 1];
    const label = React.createElement(Label, {
      lang, id:1, stack, classPrefix: 'symbol'
    });

    const inner:any = 
      spec.hidden ? [] 
      : spec.fixed ? [ React.createElement(Fixed, {
        ...this.props,
        id: 'inner',
        key: 'inner',
        stack: [...this.props.stack, spec.fixed ],
        mode: spec.iterator_mode
      }) ] : spec.subject ? [ React.createElement(Dynamic, {
        ...this.props,
        id: 'inner',
        key: 'inner',
        stack: [ ...this.props.stack, spec.subject ],
        mode: spec.iterator_mode
      }) ] :
      spec.domain ? [ React.createElement(Domain.AsFormItems, {
        ...this.props,
        id: 'inner',
        key: 'inner',
        stack: [ ...this.props.stack, spec.domain ],
        mode: spec.iterator_mode
      }) ] : null;

    return React.createElement('div', {
      key: this.props.id
    }, inner);
  }
}

class AsTableHeaders extends React.Component<RenderProps, { }> {
  render() {
    const lang = this.props.lang || 'en_US';
    const stack = this.props.stack;
    const spec = stack[stack.length - 1];
    const label = React.createElement(Label, {
      lang, id:1, stack, classPrefix: 'symbol'
    });

    const inner:any = 
      spec.fixed ? React.createElement(Fixed, {
        ...this.props,
        id: 'inner',
        key: 'inner',
        stack: [...this.props.stack, spec.fixed ],
        mode: spec.iterator_mode
      }) : spec.subject ? React.createElement(Dynamic, {
        ...this.props,
        id: 'inner',
        key: 'inner',
        stack: [ ...this.props.stack, spec.subject ],
        mode: spec.iterator_mode
      }) :
      spec.domain ? React.createElement(Domain.AsTableHeaders, {
        ...this.props,
        id: 'inner',
        key: 'inner',
        stack: [ ...this.props.stack, spec.domain ],
        mode: spec.iterator_mode
      }) : null;

    return React.createElement('td', {
      key: this.props.id
    }, [ inner ]);
  }
}

class AsTableItems extends React.Component<RenderProps, { }> {
  render() {
    const lang = this.props.lang || 'en_US';
    const stack = this.props.stack;
    const spec = stack[stack.length - 1];
    const label = React.createElement(Label, {
      lang, id:1, stack, classPrefix: 'symbol'
    });

    const inner:any = 
      spec.fixed ? React.createElement(Fixed, {
        ...this.props,
        id: 'inner',
        key: 'inner',
        stack: [...this.props.stack, spec.fixed ],
        mode: spec.iterator_mode
      }) : spec.subject ? React.createElement(Dynamic, {
        ...this.props,
        id: 'inner',
        key: 'inner',
        stack: [ ...this.props.stack, spec.subject ],
        mode: spec.iterator_mode
      }) :
      spec.domain ? React.createElement(Domain.AsTableItems, {
        ...this.props,
        id: 'inner',
        key: 'inner',
        stack: [ ...this.props.stack, spec.domain ],
        mode: spec.iterator_mode
      }) : null;

    return React.createElement('td', {
      key: this.props.id
    }, [ inner ]);
  }
}

export default { Fixed, AsFormSections, AsFormItems, AsTableHeaders, AsTableItems };
