import moment from 'moment';
import _ from 'lodash';

export class Task {
    parent?: Task;
    id: number;
    purpose?: string;
    startTime?: moment.Moment;
    endTime?: moment.Moment;
    unitsExpected: { [key:string]:number };
    unitsConsumed: { [key:string]:number };

    warnings: any[];
    errors: any[];
    args: any[];
    kwargs: { [key:string]:any };
    returnValue?: any;
    exception?: any;
    status?: any;

    constructor(parent?:Task) {
        this.parent = parent;
        this.id = _.random(0x7FFFFFFF);
        this.warnings = [];
        this.errors = [];
        this.args = [];
        this.kwargs = {};
        this.unitsExpected = {};
        this.unitsConsumed = {};
    }

    toJSON() {
        return {
            ...this.parent && { parent_id: `0x${this.parent.id.toString(16)}` },
            ...this.id && { id: `0x${this.id.toString(16)}` },
            ...this.purpose && { purpose: this.purpose.toString() },
            ...this.startTime && { startTime: this.startTime.toISOString() },
            ...this.endTime && { endTime: this.endTime.toISOString() },
            warnings: this.warnings,
            errors: this.errors,
            args: this.args,
            kwargs: this.kwargs,
            ...this.returnValue && { returnValue: this.returnValue },
            ...this.exception && { exception: this.exception },
            ...this.status && { status: this.status }
        }
    }


    start() {
        this.startTime = moment();
        this.status = 'BEGIN';
    }

    end() {
        this.endTime = moment();
        this.status = 'DONE';
    }
    
}

