import React from 'react';
import { Input, Spin, Form } from 'antd';
import { Label, Affix, Affixes } from './Conversation';
import { Moment } from './Moment';
import { Toggle } from './Toggle';
import { StepSlider } from './StepSlider';
import { Selector } from './Selector';
import { Progress } from './Progress';

import { DialogueContext } from './DialogueService'

export interface DomainProps {
    stack:any[], 
    lang: string, 
    defn: any|null,
    id: number|string,
    inspector?: any
}

class AsFormSections extends React.Component<{
    stack: Array<any>,
    lang: string,
    id: number
},{ 
    defn: any|null;
}> {
    static domain_components = [ Moment, Toggle, StepSlider, Selector, Progress ];

    static canRender(state:any) {
        const components = this.domain_components.filter((renderer:any) => renderer.canRender(state));
        return components.length > 0;
    }

    static contextType = DialogueContext;

    constructor(props:any) {
        super(props);
        this.state = { defn: null };
    }

    componentDidMount() {
        this.context.describe(this.props.stack[this.props.stack.length - 1].proto)
        .then((definitions:Array<any>) => {
            this.setState({ defn: definitions[0] })
        })
        .catch((err:any) => {
            console.error(err);
            return Promise.reject(err);
        });
    }
    
    render() {
        const lang = this.props.lang || 'en_US';
        const writeback = this.props.stack[this.props.stack.length - 1];
        const state = {
            ...this.state.defn,
            ...writeback
        };
        const newStack = [
            ...this.props.stack.slice(0,this.props.stack.length - 1),
            state
        ];
        //console.log(`DEBUG: building domain view for ${state.prop_path.path} using defn ${JSON.stringify(this.state.defn)}`);
        const components = AsFormSections.domain_components.filter((renderer:any) => renderer.canRender(state));
        const renderProps = {
            ...this.props,
            labeled: false,
            mode: 'elaborate'
        };
        if(!this.state.defn) {
            return React.createElement(Spin, { 
                key: this.props.id,
                size: 'large' 
            });
        } else if(components.length > 0) {
            // If one of the higher-order components knows how to render this spec,
            // use the first one that matched
            return React.createElement(Affixes, renderProps, [
                React.createElement(components[0], {
                    key: 'domain-control',
                    stack: this.props.stack,
                    lang,
                    defn: this.state.defn,
                    id: `${state.prop_path.path}-HOC`,
                })
            ]);
        } else if(state.properties) {
            return React.createElement(Affixes, renderProps,
                Object.getOwnPropertyNames(state.properties).map((propName:any, index:number):any => 
                    React.createElement(AsFormItems, {
                        ...this.props,
                        key: index,
                        stack: this.props.stack.concat([ state.properties[propName] ])
                    })
                )
            );
        } else {
            // If none of the higher-order components knows how to render this spec,
            // fallback to a plain text input box
            const spec = {
                ...state,
                defn: this.state.defn
            }
            const defaultValue = spec.value_after || spec.fixed || spec.default;

            if(spec.modes.multiple) {
                return React.createElement(Affixes, renderProps, [
                    React.createElement(Input.TextArea, {
                        style: { width: '100%' },
                        autoSize: true,
                        defaultValue,
                        onChange: (event:any):void => { writeback.value_after = event.target.value; },
                        key: `${state.prop_path.path}-Input`
                    })
                ]);
            } else {
                return React.createElement(Affixes, renderProps, [
                    React.createElement(Input, {
                        style: { width: '100%' },
                        defaultValue,
                        onChange: (event:any):void => { writeback.value_after = event.target.value; },
                        key: `${state.prop_path.path}-Input`
                    })
                ]);
            }
        }    
    }
}

class AsFormItems extends React.Component<{
    stack: Array<any>,
    lang: string,
    id: number,
    inspector?: any
},{ 
    defn: any|null;
}> {
    static domain_components = [ Moment, Toggle, StepSlider, Selector, Progress ];

    static canRender(state:any) {
        const components = this.domain_components.filter((renderer:any) => renderer.canRender(state));
        return components.length > 0;
    }

    static contextType = DialogueContext;

    constructor(props:any) {
        super(props);
        this.state = { defn: null };
    }

    componentDidMount() {
        this.context.describe(this.props.stack[this.props.stack.length - 1].proto)
        .then((definitions:Array<any>) => {
            this.setState({ defn: definitions[0] })
        })
        .catch((err:any) => {
            console.error(err);
            return Promise.reject(err);
        });
    }
    
    onChange(writeback:any, event:any):void { 
        writeback.value_after = event.target.value; 
        if(writeback.notify_revise && this.props.inspector) {
            this.props.inspector.doRevise();
        }
    }

    render() {
        const lang = this.props.lang || 'en_US';
        const writeback = this.props.stack[this.props.stack.length - 1];
        const state = {
            ...this.state.defn,
            ...writeback
        };
        const newStack = [
            ...this.props.stack.slice(0,this.props.stack.length - 1),
            state
        ];
        //console.log(`DEBUG: building domain view for ${state.prop_path.path} using defn ${JSON.stringify(this.state.defn)}`);
        const components = AsFormItems.domain_components.filter((renderer:any) => renderer.canRender(state));
        const label = React.createElement('div', { style: { whiteSpace: 'normal' } }, [
            React.createElement(Label, {
                ...this.props,
                key: 'label',
                classPrefix: `${state.name}-label`
            })
        ]);
        const cue = React.createElement(Affix, {
            ...this.props,
            key: 'affix',
            propertyName: 'cue',
            classPrefix: `${state.name}-cue`
        });

        if(!this.state.defn) {
            return React.createElement(Spin, { 
                key: this.props.id,
                size: 'large' 
            });
        } else if(components.length > 0) {
            // If one of the higher-order components knows how to render this spec,
            // use the first one that matched
            return React.createElement(Form.Item, {
                label,
                key: state.name,
                children: [
                    React.createElement(components[0], {
                        key: 'domain-hoc',
                        stack: this.props.stack,
                        lang,
                        defn: this.state.defn,
                        id: `${state.prop_path.path}-HOC`,
                        inspector: this.props.inspector
                    })    
                ]
            });
        } else if(state.properties) {
            return Object.getOwnPropertyNames(state.properties).map((propName:any, index:number):any => 
                React.createElement(AsFormItems, {
                    ...this.props,
                    key: index,
                    stack: this.props.stack.concat([ state.properties[propName] ])
                })
            );
        } else {
            // If none of the higher-order components knows how to render this spec,
            // fallback to a plain text input box
            const spec = {
                ...state,
                defn: this.state.defn
            }
            const defaultValue = spec.value_after || spec.fixed || spec.default;

            if(spec.modes.multiple) {
                return React.createElement(Form.Item, {
                    label,
                    key: state.name,
                    children: [
                        React.createElement(Input.TextArea, {
                            style: { width: '100%' },
                            autoSize: true,
                            defaultValue,
                            onChange: this.onChange.bind(this, writeback),
                            key: `${state.prop_path.path}-Input`,
                        })
                    ]
                });
            } else {
                return React.createElement(Form.Item, {
                    label,
                    key: state.name,
                    children: [
                        React.createElement(Input, {
                            style: { width: '100%' },
                            defaultValue,
                            onChange: this.onChange.bind(this, writeback),
                            key: `${state.prop_path.path}-Input`,
                        })
                    ]
                });
            }
        }    
    }
}

class AsTableHeaders extends React.Component<{
    stack: Array<any>,
    lang: string,
    id: number
},{ 
    defn: any|null;
}> {
    static domain_components = [ Moment, Toggle, StepSlider, Selector, Progress ];

    static canRender(state:any) {
        const components = this.domain_components.filter((renderer:any) => renderer.canRender(state));
        return components.length > 0;
    }

    static contextType = DialogueContext;

    constructor(props:any) {
        super(props);
        this.state = { defn: null };
    }

    componentDidMount() {
        this.context.describe(this.props.stack[this.props.stack.length - 1].proto)
        .then((definitions:Array<any>) => {
            this.setState({ defn: definitions[0] })
        })
        .catch((err:any) => {
            console.error(err);
            return Promise.reject(err);
        });
    }
    
    render() {
        const lang = this.props.lang || 'en_US';
        const writeback = this.props.stack[this.props.stack.length - 1];
        const state = {
            ...this.state.defn,
            ...writeback
        };
        const newStack = [
            ...this.props.stack.slice(0,this.props.stack.length - 1),
            state
        ];
        //console.log(`DEBUG: building domain view for ${state.prop_path.path} using defn ${JSON.stringify(this.state.defn)}`);
        const components = AsFormItems.domain_components.filter((renderer:any) => renderer.canRender(state));
        const label = React.createElement(Label, {
            ...this.props,
            classPrefix: `${state.name}-label`
        });

        if(!this.state.defn) {
            return React.createElement(Spin, { 
                key: this.props.id,
                size: 'large' 
            });
        } else if(components.length > 0) {
            // If one of the higher-order components knows how to render this spec,
            // use the first one that matched
            return React.createElement('th', {
                key: state.name,
                style: { border: "1px solid black", width: "100%" }
            }, [ label ] );
        } else if(state.properties) {
            return Object.getOwnPropertyNames(state.properties).map((propName:any, index:number):any => 
                React.createElement(AsTableHeaders, {
                    ...this.props,
                    key: index,
                    stack: this.props.stack.concat([ state.properties[propName] ])
                })
            );
        } else {
            return React.createElement('th', {
                key: state.name,
                style: { border: "1px solid black", width: "100%" }
            }, [ label ] );
        }    
    }
}

class AsTableItems extends React.Component<{
    stack: Array<any>,
    lang: string,
    id: number
},{ 
    defn: any|null;
}> {
    static domain_components = [ Moment, Toggle, StepSlider, Selector, Progress ];

    static canRender(state:any) {
        const components = this.domain_components.filter((renderer:any) => renderer.canRender(state));
        return components.length > 0;
    }

    static contextType = DialogueContext;

    constructor(props:any) {
        super(props);
        this.state = { defn: null };
    }

    componentDidMount() {
        this.context.describe(this.props.stack[this.props.stack.length - 1].proto)
        .then((definitions:Array<any>) => {
            this.setState({ defn: definitions[0] })
        })
        .catch((err:any) => {
            console.error(err);
            return Promise.reject(err);
        });
    }
    
    render() {
        const lang = this.props.lang || 'en_US';
        const writeback = this.props.stack[this.props.stack.length - 1];
        const state = {
            ...this.state.defn,
            ...writeback
        };
        const newStack = [
            ...this.props.stack.slice(0,this.props.stack.length - 1),
            state
        ];
        //console.log(`DEBUG: building domain view for ${state.prop_path.path} using defn ${JSON.stringify(this.state.defn)}`);
        const components = AsFormItems.domain_components.filter((renderer:any) => renderer.canRender(state));
        const label = React.createElement(Label, {
            ...this.props,
            classPrefix: `${state.name}-label`
        });

        if(!this.state.defn) {
            return React.createElement(Spin, { 
                key: this.props.id,
                size: 'large' 
            });
        } else if(components.length > 0) {
            // If one of the higher-order components knows how to render this spec,
            // use the first one that matched
            return React.createElement('td', {
                key: state.name,
            }, [
                React.createElement(components[0], {
                    stack: this.props.stack,
                    lang,
                    defn: this.state.defn,
                    id: `${state.prop_path.path}-HOC`,
                })    
            ]);
        } else if(state.properties) {
            return Object.getOwnPropertyNames(state.properties).map((propName:any, index:number):any => 
                React.createElement(AsTableItems, {
                    ...this.props,
                    key: index,
                    stack: this.props.stack.concat([ state.properties[propName] ])
                })
            );
        } else {
            // If none of the higher-order components knows how to render this spec,
            // fallback to a plain text input box
            const spec = {
                ...state,
                defn: this.state.defn
            }
            const defaultValue = spec.value_after || spec.fixed || spec.default;

            if(spec.modes.multiple) {
                return React.createElement('td', {
                    key: state.name
                }, [
                    React.createElement(Input.TextArea, {
                        style: { width: '100%' },
                        autoSize: true,
                        defaultValue,
                        onChange: (event:any):void => { writeback.value_after = event.target.value; },
                        key: `${state.prop_path.path}-Input`,
                    })
                ]);
            } else {
                return React.createElement('td', {
                    key: state.name
                }, [
                    React.createElement(Input, {
                        style: { width: '100%' },
                        defaultValue,
                        onChange: (event:any):void => { writeback.value_after = event.target.value; },
                        key: `${state.prop_path.path}-Input`,
                    })
                ]);
            }
        }    
    }
}

export default { AsFormSections, AsFormItems, AsTableHeaders, AsTableItems };
