import { Button, Result } from 'antd';
import { Link } from 'react-router-dom';
import React from 'react';

import { Label, Affix } from './Conversation';
import { RenderProps } from './RenderProps';
import { AsChecklist } from './NavService';
import { NavContext } from './NavContext';

const buttonStyle = { 
    marginTop: '16px' 
};

const formStyle = {
    maxWidth: '400px',
};

export class Completed extends React.Component<RenderProps, {
    returnState?: any
}> {
    static contextType = NavContext;

    componentDidMount() {
        this.context.getReturnState().then((returnState:any) => this.setState({ returnState }));
    }

    render() {
        const stack = this.props.stack;
        const spec = stack[stack.length - 1];
        const lang = this.props.lang || 'en_US';

        const dialogue_items = spec.dialogue || [];
        const confirmations = dialogue_items.filter((item:any):any => item.sense === 'confirm');
        
        const returnState = this.state && this.state.returnState;
        const checklist = !returnState ? [] : [ React.createElement(AsChecklist, {
            key: 'completed-checklist',
            ...this.props,
            stack: [ returnState ]
        }) ];
        const toHome = [ React.createElement(Link, { 
            key: 'completed-link-home',
            to: '/' 
        }, [
            React.createElement(Button, {
                key: 1,
                style: buttonStyle,
            }, ["Home"] )
         ]) ];
        const result = React.createElement(Result, {
            key: 'result',
            status: 'success',
            ...confirmations && confirmations[0] && { title: React.createElement(Label, {
                lang, 
                id: this.props.id,
                stack: [ confirmations[0] ],
                classPrefix: 'confirmation'
            }) },
            ...confirmations && confirmations[0] && { subTitle: React.createElement(Affix, {
                lang, 
                id: this.props.id,
                stack: [ confirmations[0] ],
                classPrefix: 'confirmation',
                propertyName: 'cue'
            }) },
            style: formStyle
        });
        return React.createElement('div', { key: this.props.id }, [ result, ...checklist, ...toHome ]);
    }
}
