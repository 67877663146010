import React from 'react';
import { InputNumber, Slider } from 'antd';
import { SliderValue } from 'antd/lib/slider';
import { DomainProps } from './Domain';

export class StepSlider extends React.Component<DomainProps, { 
  value:number|[number,number]|undefined
}> {
    static canRender(spec:any) {
        return(spec.values && spec.step);
    }

    onChange1(state:any, value:number|undefined):void { 
      state.value_after = value; 
      this.setState({value}); 
      if(state.notify_revise && this.props.inspector) {
        this.props.inspector.doRevise();
      }
    }

    onChange2(state:any, value:SliderValue):void { 
      state.value_after = value; 
      this.setState({value}); 
      if(state.notify_revise && this.props.inspector) {
        this.props.inspector.doRevise();
      }
    }

    render() {
      const state = this.props.stack[this.props.stack.length - 1];
      const spec = {
          ...this.props.defn,
          ...state
      };
    const minValue = Math.min(...spec.values);
      const maxValue = Math.max(...spec.values);
      const numeric = React.createElement(InputNumber, {
        key: `${spec.prop_path.path}-InputNumber`,
        min: minValue,
        max: maxValue,
        step: spec.step,
        value: spec.value_after || spec.default,
        disabled: !!spec.fixed,
        onChange: this.onChange1.bind(this,state)
      })
      const slider = React.createElement(Slider, {
        key: `${spec.prop_path.path}-Slider`,
        min: minValue,
        max: maxValue,
        step: spec.step,
        value: !spec.value_after ? spec.default : typeof (spec.value_after) === 'number' ? spec.value_after : 0,
        disabled: !!spec.fixed,
        onChange: this.onChange2.bind(this, state)
      });
      return [numeric, slider];
    }
}
