import React from 'react';
import moment from 'moment';

import { AuthContext } from './AuthService';
import { theModel } from './Model';
import config from './config.json';
import { Task } from './task';
import definitions from './definitions.json';

const { createContext } = React;

export interface DialogueCallbacks {
    //get:any|null, 
    catalog:any|null,
    describe:any|null,
    statement:any|null,
    comment:any|null,
    reminder:any|null,
    message: string|null,
}

export const DialogueContext:React.Context<DialogueCallbacks> = createContext<DialogueCallbacks>({
    //get: null, 
    catalog: null,
    describe: null,
    statement: null,
    comment: null,
    reminder: null,
    message: null
});

export class DialogueProvider extends React.Component<DialogueCallbacks, {
    pending: Promise<any>[];
    requestTask?:Task;
}> {
    static contextType = AuthContext;
    defn_name_to_promise: { [key:string]:Promise<any> };

    constructor(props:DialogueCallbacks) {
        super(props);
        this.state = {
            pending: [],
            requestTask: undefined
        };
        this.defn_name_to_promise = {};
        Object.keys(definitions).forEach((purename:string) => {
            this.defn_name_to_promise[purename] = Promise.resolve([ (<any>definitions)[purename] ]);
        });
    }

    abortController = new AbortController();

    componentWillUnmount() {
        this.abortController.abort();
    }

    get_endpoint(defn:string, verb:string): string {
        const app_config = config as any;
        const location_host = process.env.NODE_ENV === 'test' ? 'localhost' : window.location.host;
        const service_desc = 
            location_host == 'localhost' || location_host == app_config.services[`ui@test`].host ? app_config.services[`dialogue@test`] :
            location_host == app_config.services[`ui@integration`].host ? app_config.services[`dialogue@integration`] :
            location_host == app_config.services[`ui@production`].host ? app_config.services[`dialogue@production`] :
            app_config.services[`dialogue@${process.env.REACT_APP_SERVICE_ENV || 'production'}`];
        const protocol = process.env.REACT_APP_API_PROTOCOL || service_desc.protocol || 'https';
        const host = process.env.REACT_APP_API_HOST || service_desc.host || 'localhost';
        const path = process.env.REACT_APP_API_PATH || service_desc.path || '/';
        const url = this.context.accessToken
            ? `${protocol}://${host}${path}${verb}/${defn.split('.').join('/')}`
            : `${protocol}://${host}${path}${verb}`;
        return url;
    }

    get_uri(defn:string): string {
        const app_config = config as any;
        const protocol = window.location.protocol;
        const location_host = process.env.NODE_ENV === 'test' ? 'localhost' : window.location.host;
        const terms = defn.split('.');
        if(terms.length == 0) {
            return `${protocol}//${location_host}}`;
        } else {
            return `${protocol}//${location_host}/${terms.join('/')}`;
        }
    }

    load_cctx(properties:any) {
        this.load_cctx_r(properties,['cctx']);
    }

    load_cctx_r(properties:any, path:string[]) {
        Object.entries(properties).forEach( ([name, prop]:[string, any]) => {
            const sub_path_str = path.concat(name).join('.');
            if(sessionStorage.getItem(sub_path_str) &&
                prop.sense !== 'tell') {
                if(prop.domain) {
                    const stored = sessionStorage.getItem(sub_path_str);
                    if(stored === null) {
                        prop.domain.value_after = null;
                    } else {
                        prop.domain.value_after = JSON.parse(stored);
                    }
                } else if(prop.subject) {
                    prop.subject.value_after = sessionStorage.getItem(sub_path_str);
                }
            }
            if(prop.properties) {
                this.load_cctx_r(prop.properties, path.concat([name]));
            }
        });
    }

    save_cctx(properties:any) {
        this.save_cctx_r(properties, ['cctx']);
    }

    save_cctx_r(properties:any, path:string[]) {
        Object.entries(properties).forEach( ([name, prop]:[string, any]) => {
            const sub_path_str = path.concat(name).join('.');
            const value_after = 
                prop.domain ? prop.domain.value_after :
                prop.subject ? prop.subject.value_after :
                prop.value_after;
            if(value_after && prop.sense !== 'tell') {
                sessionStorage.setItem(sub_path_str, JSON.stringify(value_after));
            }
            if(prop.properties) {
                this.save_cctx_r(prop.properties, path.concat([name]));
            }
        });
    }

    preprocess(dialogue:any) {
        this.load_cctx(dialogue.reduce((as_map:any, item:any) => {
            if(item.name) {
                as_map[item.name] = item;
            }
            return as_map;
        },{}));
    }

    postprocess(dialogue:any) {
        this.save_cctx(dialogue.reduce((as_map:any, item:any) => {
            if(item.name) {
                as_map[item.name] = item;
            }
            return as_map;
        },{}));
    }

    /*async get(statement:string): Promise<any> {
        return fetch(this.get_endpoint(statement), { 
            mode: "cors"
        })
        .then(res => res.json())
        .then(result => {
            if(result.msg) {
                return Promise.reject({
                    '@class': 'NetworkError',
                    message: result.msg
                });    
            } else if(result.dialogue) {
                this.preprocess(result.dialogue);
            }
            return result;
        })
        .catch(error => {
            return Promise.reject({
                '@class': 'NetworkError',
                message: error
            });
        });
    }*/
    
    logTaskStatus(task:Task): void {
/*         fetch(this.get_endpoint('', 'task'), {
            mode: "cors",
            method: "POST",
            cache: "no-cache",
            headers: {
                'Content-Type': 'application/json',
                ...this.context.accessToken && { Authorization: `Bearer ${this.context.accessToken}` }
            },
            body: JSON.stringify({
                ...task.toJSON(),
                ...!this.context.accessToken && { 'anonymous_id': this.context.anonymous_id },
                'journey_id': this.context.journeyTask.id,
                'ui_timestamp': moment()
            })
        })
 */    }
    onRequest(verb:string, data:any): void {
        const journeyTask = this.context.journeyTask;
        this.logTaskStatus(journeyTask);
        if(!this.state.requestTask) {
            const requestTask = new Task(journeyTask);
            requestTask.purpose = verb;
            requestTask.start();
            this.logTaskStatus(requestTask);
            this.setState({requestTask});
        }
    }

    onResponse(verb:string, data:any): void {
        if(this.state.requestTask) {
            if(this.state.requestTask.parent) {
                this.logTaskStatus(this.state.requestTask.parent);
            }
            if(data && data.status === 'completed') {
                this.state.requestTask.end();
                this.logTaskStatus(this.state.requestTask);
                this.setState({requestTask: undefined});
            }
        }
    }

    async catalog(): Promise<any> {
        return fetch(this.get_endpoint('', 'catalog'), {
            signal: this.abortController.signal,
            mode: "cors",
            method: "POST",
            cache: "no-cache",
            headers: {
                'Content-Type': 'application/json',                    
                ...this.context.accessToken && { Authorization: `Bearer ${this.context.accessToken}` }
            },
            body: JSON.stringify({
                ...!this.context.accessToken && { 'anonymous_id': this.context.anonymous_id },
                'journey_id': this.context.journeyTask.id,
                'ui_timestamp': moment()
            })
        })
        .then(res => res.text())
        .then(res => {
            try {
                return JSON.parse(res);
            } catch(error) {
                return Promise.reject(`ERROR while parsing JSON message: ${error}\nMESSAGE: ${res}`);
            }
        })
        .catch(error => {
            if(error.name == 'AbortError') 
                return;
            return Promise.reject(error);
        });
    }

    async describe(ref:any): Promise<any> {
        const defn_name = ref.qualname;
        if(this.defn_name_to_promise[defn_name]) {
            return this.defn_name_to_promise[defn_name];
        }
        const promise = fetch(this.get_endpoint(defn_name, 'describe'), {
            signal: this.abortController.signal,
            mode: "cors",
            method: "POST",
            cache: "no-cache",
            headers: {
                'Content-Type': 'application/json',                    
                ...this.context.accessToken && { Authorization: `Bearer ${this.context.accessToken}` }
            },
            body: JSON.stringify({
                '@refs': ref,
                ...!this.context.accessToken && { 'anonymous_id': this.context.anonymous_id },
                'journey_id': this.context.journeyTask.id,
                'ui_timestamp': moment()
            })
        })
        .then(res => res.text())
        .then(res => {
            try {
                return JSON.parse(res);
            } catch(error) {
                return Promise.reject(`ERROR while parsing JSON message: ${error}\nMESSAGE: ${res}`);
            }
        })
        .then(res => theModel().fromJSON(res))
        .then(res => {
            const promise = this.defn_name_to_promise[defn_name];
            this.defn_name_to_promise[defn_name] = Promise.resolve(res);
            this.setState({ pending: this.state.pending.filter((item:any):boolean => item !== promise) });
            return res;
        })
        .catch(error => {
            if(error.name == 'AbortError') 
                return;
            return Promise.reject(error);
        });
        this.defn_name_to_promise[defn_name] = promise;
        this.setState({ pending: this.state.pending.concat([promise]) });
        return promise;
    }
    
    async statement(verb:string, statement:string, data:any): Promise<any> {
        this.onRequest('statement', data);
        return Promise.race([
            new Promise(r => setTimeout(r, 20000)).then(() => Promise.reject("Server Communication Error : Request timed out")),
            fetch(this.get_endpoint(statement, 'statement'), {
                signal: this.abortController.signal,
                mode: "cors",
                method: "POST",
                cache: "no-cache",
                headers: {
                    'Content-Type': 'application/json',                    
                    ...this.context.accessToken && { Authorization: `Bearer ${this.context.accessToken}` }
                },
                body: JSON.stringify({
                    ...data,
                    ...!data && { '@statement': statement },
                    ...!this.context.accessToken && { 'anonymous_id': this.context.anonymous_id },
                    'journey_id': this.context.journeyTask.id,
                    'ui_timestamp': moment(),
                    '@verb': verb
                })
            })
            .then(response => response.json())
            .then(result => {
                this.onResponse('statement', result);
                if(result.dialogue) {
                    this.postprocess(result.dialogue);
                }
                return result;
            })
            .then(details => {
                if(details.msg) {
                    return Promise.reject(JSON.stringify({
                        '@class': 'PostError',
                        message: details.msg,
                    }));
                } else {
                    return details;
                }
            })
            .catch(error => {
                if(error.name == 'AbortError') 
                    return;
                return Promise.reject(error);
            })
        ]);
    }

    async comment(isPublic:boolean, state:any): Promise<any> {
        return Promise.race([
            new Promise(r => setTimeout(r, 20000)).then(() => Promise.reject("Server Communication Error : Request timed out")),
            fetch(this.get_endpoint('', 'comment'), {
                signal: this.abortController.signal,
                mode: "cors",
                method: "POST",
                cache: "no-cache",
                headers: {
                    'Content-Type': 'application/json',                    
                    ...this.context.accessToken && { Authorization: `Bearer ${this.context.accessToken}` }
                },
                body: JSON.stringify({
                    '@class': 'Comment.State',
                    isPublic,
                    ...state,
                    ...!this.context.accessToken && { 'anonymous_id': this.context.anonymous_id },
                    'journey_id': this.context.journeyTask.id,
                    'ui_timestamp': moment()
                })
            })
            .then(response => response.json())
            .then(details => {
                if(details.msg) {
                    return Promise.reject(JSON.stringify({
                        '@class': 'PostError',
                        message: details.msg,
                    }));
                } else {
                    return details;
                }
            })
            .catch(error => {
                if(error.name == 'AbortError') 
                    return;
                return Promise.reject(error);
            })
        ]);
    }

    async reminder(notify_whom:string, notify_time:moment.Moment, notify_active:boolean, statement_defn:string): Promise<any> {
        const active = (!!notify_whom && !!notify_time && notify_active && !!statement_defn);
        const suffix = statement_defn.replace(/\./g,"-");
        const key=`reminder-${notify_whom}-${suffix}`;
        const data = {
            '@class': 'SendSmsMessage',
            'to': notify_whom, 
            'at': notify_time, 
            'body': `Time to return to the Smart Citizens App: ${this.get_uri(statement_defn)}`,
            key,
            active
        };
        return Promise.race([
            new Promise(r => setTimeout(r, 20000)).then(() => Promise.reject("Server Communication Error : Request timed out")),
            fetch(this.get_endpoint('', 'action'), {
                signal: this.abortController.signal,
                mode: "cors",
                method: "POST",
                cache: "no-cache",
                headers: {
                    'Content-Type': 'application/json',                    
                    ...this.context.accessToken && { Authorization: `Bearer ${this.context.accessToken}` }
                },
                body: JSON.stringify({
                    ...data,
                    ...!this.context.accessToken && { 'anonymous_id': this.context.anonymous_id },
                    'journey_id': this.context.journeyTask.id,
                    'ui_timestamp': moment()
                })
            })
            .then(response => response.json())
            .then(result => {
                if(result.dialogue) {
                    this.postprocess(result.dialogue);
                }
                return result;
            })
            .then(details => {
                if(details.msg) {
                    return Promise.reject(JSON.stringify({
                        '@class': 'PostError',
                        message: details.msg,
                    }));
                } else {
                    return details;
                }
            })
            .catch(error => {
                if(error.name == 'AbortError') 
                    return;
                return Promise.reject(error);
            })
        ]);
    }

    render() {
        return React.createElement(DialogueContext.Provider, {
            value: {
                // get: this.props.get || this.get.bind(this),
                catalog: this.props.catalog || this.catalog.bind(this),
                describe: this.props.describe || this.describe.bind(this),
                statement: this.props.statement || this.statement.bind(this),
                comment: this.props.comment || this.comment.bind(this),
                reminder: this.props.reminder || this.reminder.bind(this),
                message: null
            }
        }, this.props.children);
    }
}
