import React from 'react';
import { Button } from 'antd';
import Symbol from './Symbol';
import { Dynamic } from './Dynamic';
import { RenderProps } from './RenderProps';

const buttonStyle = { 
    marginTop: '16px' 
};
  
interface submitLabelMapType {
    [name: string]: string
}
const submitLabelMap: submitLabelMapType = {
    'proposed': 'Submit',
    'revised': 'Submit',
    'submitted': 'Submitted ...',
    'committed': 'Committed',
    'canceled': 'Canceled'
};

class AsFormSections extends React.Component<RenderProps, {
    symbols:any[]|null
}> {
    constructor(props:RenderProps) {
        super(props);
        const stack = this.props.stack;
        const spec = stack[stack.length - 1];
        this.state = {
            symbols: null
        };
    }

    static getDerivedStateFromProps(props:RenderProps, state:any) {
        const stack = props.stack;
        const spec = stack[stack.length - 1];
        return {
            symbols: !spec.dialogue ? null : spec.dialogue.filter((element:any): boolean => !element.modes.hidden && element.sense !== 'confirm' && !element.fact)
        }
    }
    
    mutate( cb:any ) {
        const symbols = this.state.symbols || [];
        const count = symbols.reduce((count:number, symbol:any):number => count + cb(symbol) ? 1 : 0, 0);
        if(count > 0) {
          this.setState({ symbols: this.state.symbols });
        }
    }
    
    render() {
        const stack = this.props.stack;
        const symbol = stack.length > 1 ? stack[stack.length - 2] : null;
        const spec = stack[stack.length - 1];
        const lang = this.props.lang || 'en_US';

        if(spec['@class'] === 'Statement.State') {
            const symbols = this.state.symbols || [];
            const children = symbols.map((element:any, index:number): React.ReactElement => {
                return React.createElement(Symbol.AsFormSections, { 
                    ...this.props,
                    key: element.name,
                    stack: [ ...stack, element], 
                    id: index+1,
                    labeled: undefined
                });
            });
            return children;
        } else if(spec['@class'] === 'Symbol.State'){    
            return React.createElement(Symbol.AsFormSections, { 
                ...this.props,
                key: `${spec.prop_path.path}-Symbol`,
                stack: [...stack, spec]
            });
        } else if(spec['@class'] === 'Subject.State') {
            return spec.proto.qualname;
        } else if(spec['@class'] === 'Entity.Def') {
            return [];
        } else if(spec['@class'] === 'Domain.State') {
            // Go through the list of higher-order components and check if any of them
            // knows how to render this spec
            return React.createElement(Symbol.AsFormSections, { 
                ...this.props,
                key: 'domain-state',
                id: `${spec.prop_path.path}-Symbol`,
                stack: [...stack, spec]
            });
        }
        return React.createElement('div', { }, 
            "ERROR: renderDynamic cannot render this " + JSON.stringify(spec)
        );
    }
}

class AsFormItems extends React.Component<RenderProps, {
    symbols:any[]|null
}> {
    constructor(props:RenderProps) {
        super(props);
        const stack = this.props.stack;
        const spec = stack[stack.length - 1];
        this.state = {
            symbols: null
        };
    }

    static getDerivedStateFromProps(props:RenderProps, state:any) {
        const stack = props.stack;
        const spec = stack[stack.length - 1];
        return {
            symbols: !spec.dialogue ? null : spec.dialogue.filter((element:any): boolean => !element.modes.hidden && element.sense !== 'confirm' && !element.fact)
        }
    }
    
    mutate( cb:any ) {
        const symbols = this.state.symbols || [];
        const count = symbols.reduce((count:number, symbol:any):number => count + cb(symbol) ? 1 : 0, 0);
        if(count > 0) {
          this.setState({ symbols: this.state.symbols });
        }
    }
    
    render() {
        const stack = this.props.stack;
        const symbol = stack.length > 1 ? stack[stack.length - 2] : null;
        const spec = stack[stack.length - 1];
        const lang = this.props.lang || 'en_US';

        if(spec['@class'] === 'Statement.State') {
            const symbols = this.state.symbols || [];
            const children = symbols.map((element:any, index:number): React.ReactElement => {
                return React.createElement(Symbol.AsFormItems, { 
                    ...this.props,
                    key: element.name,
                    stack: [ ...stack, element], 
                    id: index+1,
                    labeled: undefined
                });
            });
            return children;
        } else if(spec['@class'] === 'Symbol.State'){    
            return React.createElement(Symbol.AsFormItems, { 
                ...this.props,
                key: `${spec.prop_path.path}-Symbol`,
                stack: [...stack, spec]
            });
        } else if(spec['@class'] === 'Subject.State') {
            return spec.proto.qualname;
        } else if(spec['@class'] === 'Entity.Def') {
            return [];
        } else if(spec['@class'] === 'Domain.State') {
            // Go through the list of higher-order components and check if any of them
            // knows how to render this spec
            return React.createElement(Symbol.AsFormItems, { 
                ...this.props,
                key: 'domain-state',
                id: `${spec.prop_path.path}-Symbol`,
                stack: [...stack, spec]
            });
        }
        return React.createElement('div', { }, 
            "ERROR: renderDynamic cannot render this " + JSON.stringify(spec)
        );
    }
}

class AsTableHeaders extends React.Component<RenderProps, {}> {
    render() {
        const stack = this.props.stack;
        const symbol = stack.length > 1 ? stack[stack.length - 2] : null;
        const spec = stack[stack.length - 1];
        const lang = this.props.lang || 'en_US';

        if(spec['@class'] === 'Symbol.State') {
            return React.createElement(Symbol.AsTableHeaders, { 
                ...this.props,
                key: `${spec.prop_path.path}-Symbol`,
                stack: [...stack, spec]
            });
        }
        return React.createElement('div', { }, 
            "ERROR: renderDynamic cannot render this " + JSON.stringify(spec)
        );
    }
}

class AsTableItems extends React.Component<RenderProps, {}> {        
    render() {
        const stack = this.props.stack;
        const symbol = stack.length > 1 ? stack[stack.length - 2] : null;
        const spec = stack[stack.length - 1];
        const lang = this.props.lang || 'en_US';

        if(spec['@class'] === 'Symbol.State') {
            return React.createElement(Symbol.AsTableItems, { 
                ...this.props,
                key: `${spec.prop_path.path}-Symbol`,
                stack: [...stack, spec]
            });
        }
        return React.createElement('div', { }, 
            "ERROR: renderDynamic cannot render this " + JSON.stringify(spec)
        );
    }
}

class Actions extends React.Component<RenderProps, {
    symbols:any[]|null
}> {
    constructor(props:RenderProps) {
        super(props);
        const stack = this.props.stack;
        const spec = stack[stack.length - 1];
        this.state = {
            symbols: null
        };
    }

    static getDerivedStateFromProps(props:RenderProps, state:any) {
        const stack = props.stack;
        const spec = stack[stack.length - 1];
        return {
            symbols: !spec.dialogue ? null : spec.dialogue.filter((element:any): boolean => !element.modes.hidden && element.sense !== 'confirm' && !element.fact)
        }
    }
    
    mutate( cb:any ) {
        const symbols = this.state.symbols || [];
        const count = symbols.reduce((count:number, symbol:any):number => count + cb(symbol) ? 1 : 0, 0);
        if(count > 0) {
          this.setState({ symbols: this.state.symbols });
        }
    }
    
    render() {
        const stack = this.props.stack;
        const symbol = stack.length > 1 ? stack[stack.length - 2] : null;
        const spec = stack[stack.length - 1];
        const lang = this.props.lang || 'en_US';

        if(spec['@class'] === 'Statement.State' && spec.can_write) {
            const symbols = this.state.symbols || [];
            const deactivate = spec.status === 'comitted' || spec.status === 'canceled';
            const status: string = spec.status;
            const submitLabel = 
                status == 'submitted' ? submitLabelMap[status] :
                spec.submit ? spec.submit[lang] :
                symbol && symbol.submit ? symbol.submit[lang] :                
                submitLabelMap[status];
            const submitButton = deactivate ? submitLabel :
                React.createElement(Button, {
                className: 'statement-submit-button',
                key: '_submit',
                type: 'primary',
                htmlType: 'submit',
                style: buttonStyle
                }, submitLabel);
            const actions = React.createElement('div', {
                key: '_actions'
            }, [ submitButton ]);
            return actions;
        } else {
            return [];
        }
        return React.createElement('div', { }, 
            "ERROR: renderDynamic cannot render this " + JSON.stringify(spec)
        );
    }
}

export default { AsFormSections, AsFormItems, AsTableHeaders, AsTableItems, Actions };
