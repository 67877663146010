import React from 'react';
import { Alert, Card, Form } from 'antd';
import { DialogueContext } from './DialogueService';
import { Label } from './Conversation';
import { Dynamic } from './Dynamic';

import { CommentProvider, CommentDrawer } from './Comment';

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
};

const centerStyle: React.CSSProperties = {
    width: '100%',
    textAlign: 'center',
    display: 'block'
};

export class AsCard extends React.Component<{ lang: string, proposal: any, labeled?: any, mode: string }, { mode:string, width: number, height: number }> {
    constructor(props:any) {
        super(props);
        this.state = {
            mode: props.mode || 'paginate',
            width: 0,
            height: 0
        }
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }
    
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    
    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }
      
    render() {
        const spec = this.props.proposal;
        const justDialogue = { 
            ...spec,
            label: undefined
         };
        const label = React.createElement(Label, {
            lang: this.props.lang,
            id: 1,
            key: 1,
            stack: [spec],
            classPrefix: 'as-card'
        });
        const renderProps = {
            lang: this.props.lang,
            stack: [justDialogue],
            id: 1,
            key: 1,
            labeled: true,
            mode: this.state.mode
        };
        const content = React.createElement(Dynamic, renderProps);

        const cardStyle = {
            display: 'inline-block',
            width: this.state.width > 400 ? 400 : this.state.width
        };
        if (this.props.labeled === spec) {
            const card = React.createElement('div', {
                key: 'card',
                style: cardStyle
            }, [content]);
            return card;
        } else {
            const card = React.createElement(Card, {
                key: 'card',
                style: cardStyle,
                title: label
            }, [content]);
            return card;
        }
    }
}


export class Inspector extends React.Component<{ statement: string, id:number|string, labeled?: any, mode: string }, { details: any, postId: any, vanishing_messages: string[], alert_messages: string[] }> {
    static contextType = DialogueContext;

    constructor(spec: any) {
        super(spec);
        this.state = {
            details: null,
            postId: null,
            vanishing_messages: [],
            alert_messages: []
        }
    }

    alert(message: string): void {
        this.setState({ alert_messages: this.state.alert_messages.concat([message]) });
        new Promise(r => setTimeout(r, 2000)).then(() => {
            this.setState({
                alert_messages: this.state.alert_messages.filter((alert_message: string) => alert_message !== message),
                vanishing_messages: this.state.vanishing_messages.concat([message])
            });
            return new Promise(r => setTimeout(r, 2000)).then(() => {
                this.setState({
                    vanishing_messages: this.state.vanishing_messages.filter((alert_message: string) => alert_message !== message)
                });

            });
        });
    }

    componentDidMount(): void {
        this.fetch();
    }

    fetch(): void {
        this.context.statement('propose', this.props.statement, null).then((details: any): void => {
            this.setState({ details: details });
        }).catch((error: any) => {
            this.alert('Communication error with server');
            new Promise(r => setTimeout(r, 3000)).then(() => {
                this.fetch();
            })
        });
    }

    mutate(cb: any) {
        const count = this.state.details.dialogue.reduce((count: number, symbol: any): number => count + cb(symbol) ? 1 : 0, 0);
        if (count > 0) {
            this.setState({ details: this.state.details });
        }
    }

    async doRevise(): Promise<any> {
        return (this.context.statement('revise', this.props.statement, this.state.details))
            .then((details: any) => {
                this.setState({ details: details });
            }).catch((error: any) => {
                this.alert('Communication error with server');
            });
    }

    async doSubmit(): Promise<any> {
        if (this.state.details.status === 'proposed' || this.state.details.status === 'revised') {
            if (this.state.details.step) {
                this.state.details.step.subject.status = 'submitted';
            } else {
                this.state.details.status = 'submitted';
            }
            this.setState({ details: this.state.details });
            return (this.context.statement('commit', this.props.statement, this.state.details))
                .then((details: any) => {
                    this.setState({ details: details });
                }).catch((error: any) => {
                    this.alert('Communication error with server');
                });
        }
    }

    render(): React.ReactElement {
        const lang = 'en_US';
        if (!this.state || !this.state.details || !this.state.details['@class']) {
            //console.log(`DEBUG: Inspector(${this.props.statement}) is loading`)
            return React.createElement('div', {
                children: ["Loading ..."]
            });
        } else {
            console.log(`DEBUG: Inspector(${this.props.statement}) is rendering`)
            const card = React.createElement(AsCard, {
                lang,
                key: 1,
                proposal: this.state.details,
                labeled: this.props.labeled,
                mode: this.props.mode,
                inspector: this
            });
            const form = React.createElement(Form, {
                key: '_form',
                onFinish: this.doSubmit.bind(this),
                style: centerStyle,
                ...layout
            }, [
                ...this.state.alert_messages.map((alert_message: string, index: number) => React.createElement(Alert, {
                    key: index + 2,
                    banner: true,
                    message: alert_message
                })),
                card
            ]);
            return React.createElement(CommentProvider, {}, [
                React.createElement(CommentDrawer, {
                    key: 1
                }),
                React.createElement('div', {
                    key: 2,
                    style: centerStyle
                }, form)
            ]);
        }
    }
}
