import React from 'react';
import { DatePicker } from 'antd';
import { DomainProps } from './Domain';
import moment from 'moment';

export class Moment extends React.Component<DomainProps, { }> {
    static canRender(spec:any) {
        return(spec.proto.qualname === 'semantic.temporal.moment');
    }

    onChange(state:any, value:any):any {
      if(value) {
        const dateProps = state.properties.at.properties;
        dateProps.year.value_after = value.y;
        dateProps.month.value_after = value.M + 1;
        dateProps.dayOfMonth.value_after = value.d;
      }
      if(state.notify_revise && this.props.inspector) {
        this.props.inspector.doRevise();
      }
    }

    render() {
      const state = this.props.stack[this.props.stack.length - 1];
      const spec = {
          ...this.props.defn,
          ...state
      };
    const dateProps = state.properties.at.properties;
      const defaultValue = dateProps.year.value_after && dateProps.month.value_after && dateProps.dayOfMonth.value_after ?
        moment({
          y: dateProps.year.value_after,
          M: dateProps.month.value_after - 1,
          d: dateProps.dayOfMonth.value_after
        }) : undefined;
      const picker = React.createElement(DatePicker, { 
        key: `${spec.prop_path.path}-DatePicker`,
        defaultValue: defaultValue,
        disabled: !!spec.fixed,
        style: { width: '100%' },
        onChange: this.onChange.bind(this, state)
      });
      return picker;
    }
}
