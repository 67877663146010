import React from 'react';
import { Collapse } from 'antd';
import { Text } from './Text';
import { RenderProps } from './RenderProps';
import { CommentIcon }  from './Comment';
import { Reminder } from './Reminder';
import Divider2 from './Divider2';
import { ReactComponent } from '*.svg';

const { Panel } = Collapse;

export class Label extends React.Component<{ stack:any[], lang: string, id:number|string, classPrefix: string|null }, {}> {
  getSpec(index:number):any {
    if(index > 0 && this.props.stack[index - 1]['@class'] === 'Symbol.State') {
      const label = this.getSpec(index-1);
      if(label) {
        return label;
      }
    }

    const spec = this.props.stack[index];
    if(spec.label) {
      return this.props.stack[index];
    }
    return null;
  }
  
  render() {
    const spec = this.getSpec(this.props.stack.length - 1);
    if(spec === null) {
      return null;
    }
    return React.createElement(Text, { 
      spec: spec.label[this.props.lang] || spec.label['en_US'], 
      id: this.props.id,
      className: this.props.classPrefix ? `${this.props.classPrefix}-label` : null
    });
  }
}

export class Affix extends React.Component<{ stack:any[], lang: string, id:number|string, classPrefix: string|null, propertyName: string }, {}> {
  getSpec(index:number):any {
    const spec = this.props.stack[index];
    if(spec[this.props.propertyName]) {
      return this.props.stack[index];
    }
    return null;
  }
  render() {
    const spec = this.getSpec(this.props.stack.length - 1);
    if(spec === null) {
      return [];
    }
    const lang = this.props.lang;
    //const affix = this.getSpec(this.props.stack.length - 1);
    const affix = spec[this.props.propertyName];
    const cue = (!spec.abbreviate_cue || !affix || !Array.isArray(affix[lang])
      ? (affix
        ? React.createElement(Text, { 
          spec: affix[lang] || affix['en_US'],
          id: this.props.id,
          className: this.props.classPrefix ? `${this.props.classPrefix}-cue` : null
        })
        : null)
      : React.createElement(Collapse, {
          key: this.props.id,
          bordered: false,
          accordion: true,
          ...this.props.classPrefix && { className: `${this.props.classPrefix}-cue`}
        }, [ 
          ...affix[lang]
          .filter((item:string,index:number) => index < spec.abbreviate_cue.count)
          .map((item:string, index:number) => React.createElement(Text, { 
            spec: item, 
            id: index,
            className: this.props.classPrefix ? `${this.props.classPrefix}-cue` : null
          })),
          React.createElement(Panel, {
            key: 1,
            header: spec.abbreviate_cue.label ? spec.abbreviate_cue.label[lang] : "..."
          }, 
          affix[lang]
          .filter((item:string,index:number) => index >= spec.abbreviate_cue.count)
          .map((item:string, index:number) => React.createElement(Text, { 
            elementType: 'p',
            spec: item,
            id: index+1,
            className: this.props.classPrefix ? `${this.props.classPrefix}-${this.props.propertyName}` : null
          }))
          )
        ])
    );
    return cue;
  }
}

export class Affixes extends React.Component<RenderProps, { }> {
  render() {
    const lang = this.props.lang || 'en_US';
    const stack = this.props.stack;
    const spec = stack[stack.length - 1];
    const label = React.createElement(Label, {
      key:'label',
      lang, 
      id: 1,
      stack: [ spec ],
      classPrefix: 'statement'
    });

    const divider = this.props.labeled === spec || !spec.label || spec.iterator_mode === 'link' ? [] : [ React.createElement(Divider2, {
      key: 1,
      ...label && { orientation: 'left' }
    }, label ? [ 
      label, 
      React.createElement(CommentIcon, {
        key: 2,
        label,
        stack: this.props.stack
      })
    ] : null) ];

    const cue = React.createElement(Affix, {
      lang, id: 'cue', key: 'cue', stack, classPrefix: 'symbol', propertyName: 'cue'
    });
    const cueSpan = React.createElement('p', {
      key: 'cue',
      style: { 
        marginLeft: '10px', 
        marginRight: '10px',
      },
    }, [ cue ]);

    const review = React.createElement(Affix, {
      lang, id: 'review', key: 'review', stack, classPrefix: 'symbol', propertyName: 'review'
    });
    const reviewSpan = React.createElement('p', {
      key: 'review',
      style: { 
        marginLeft: '10px', 
        marginRight: '10px',
      },
    }, [ review ]);

    const contents = stack.length > 1 ? [ ...divider, cueSpan, this.props.children, reviewSpan ] : 
    [ ...divider, cueSpan, React.createElement(Reminder, {
      ...this.props,
      key: 'reminder'
    }, [ this.props.children, reviewSpan ]) ];

    return contents;
  }
}
