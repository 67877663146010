import React from 'react';
import { Checkbox } from 'antd';
import { DomainProps } from './Domain';

export class Toggle extends React.Component<DomainProps, { }> {
    static canRender(spec:any) {
        return(spec.values && spec.type === 'bool');
    }

    onChange(state:any, e:any):void { 
        state.value_after = e.value; 
        if(state.notify_revise && this.props.inspector) {
            this.props.inspector.doRevise();
        }
    }

    render() {
        const state = this.props.stack[this.props.stack.length - 1];
        const spec = {
            ...this.props.defn,
            ...state
        };
        return React.createElement(Checkbox, {
            key: `${spec.prop_path.path}-Checkbox`,
            defaultChecked: spec.default,
            disabled: !!spec.fixed,
            onChange: this.onChange.bind(this, state)
        });
    }
}
