import React from 'react';
import { Select } from 'antd';
import { DomainProps } from './Domain';
const { Option } = Select;

export class Selector extends React.Component<DomainProps, {
    values: any
}> {
    static canRender(spec:any) {
        return(spec.values && !spec.step);
    }

    onChange(state:any, value:any):void { 
        state.value_after = value; 
        this.setState({values: value });
        if(state.notify_revise && this.props.inspector) {
            this.props.inspector.doRevise();
        }
    }

    render() {
        const state = this.props.stack[this.props.stack.length - 1];
        const spec = {
            ...this.props.defn,
            ...state
        };
        const deactivate = spec.status === 'committed' || spec.status === 'canceled';
        const children = spec.values.map((element:any, index:number): React.ReactElement => {
            return React.createElement(Option, { 
            value: element.name || element, 
            key: element.name || element, 
            children: [ element.label ? element.label[this.props.lang] : element.name || element ]
            });
        });
        const defaultValue = spec.value_after || (spec.default ? spec.default.name || spec.default : null);
        const selectMode = spec.modes.multiple ? 'multiple' : undefined;
        const select = React.createElement(Select, { 
            key: `${spec.prop_path.path}-Select`,
            ...defaultValue && { defaultValue },
            disabled: !!spec.fixed,
            ...spec.value_after && { value: spec.value_after},
            style: { width: '100%' },
            mode: selectMode,
            onChange: this.onChange.bind(this,state),
            ...deactivate && { disabled: true },
        }, children);
        return select;
    }
}
