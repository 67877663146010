import React from 'react';
import { Alert } from 'antd';
import moment from 'moment';
import config from './config.json';
import { Moment } from './Moment';
import { Task } from './task';

const { createContext } = React;

export interface AuthCallbacks {
    alert:any|null,
    anonymous:any|null,
    login:any|null, 
    logout:any|null,
    displayUsername:string|null,
    anonymous_id:any|null,
    accessToken: string|null,
    loginStatus: 'logout'|'anonymous'|'login',
    journeyTask: Task|null
}

interface AuthState {
    displayUsername:string|null,
    anonymous_id: string|null,
    journeyTask: Task,
    accessToken: string|null,
    loginStatus: 'logout'|'anonymous'|'login',
    vanishing_messages: string[],
    alert_messages: string[]
}

export const AuthContext:React.Context<AuthCallbacks> = createContext<AuthCallbacks>({
    alert: null,
    anonymous: null,
    login: null,
    logout: null,
    displayUsername: null,
    anonymous_id: localStorage.getItem('anonymous_id'),
    journeyTask: null,
    accessToken: sessionStorage.getItem('accessToken'),
    loginStatus: 
        sessionStorage.getItem('loginStatus') === 'login' ? 'login' :
        sessionStorage.getItem('loginStatus') === 'logout' ? 'logout' :
        sessionStorage.getItem('loginStatus') === 'anonymous' ? 'anonymous' :
        localStorage.getItem('anonymous_id') ? 'anonymous' : 'logout'
});

export class AuthProvider extends React.Component<AuthCallbacks, AuthState> {
    constructor(props:any) {
        super(props);
        // Reset session sessionStorage in case it was left in an inconsistent state
        if(!sessionStorage.getItem('accessToken') && sessionStorage.getItem('username')) {
            sessionStorage.removeItem('username')
        }
        const journeyTask = new Task();
        journeyTask.purpose = 'journey';
        journeyTask.start();
        this.state = {
            displayUsername: null,
            anonymous_id: localStorage.getItem('anonymous_id'),
            journeyTask,
            accessToken: sessionStorage.getItem('accessToken'),
            loginStatus:
                sessionStorage.getItem('loginStatus') === 'login' ? 'login' :
                sessionStorage.getItem('loginStatus') === 'logout' ? 'logout' :
                sessionStorage.getItem('loginStatus') === 'anonymous' ? 'anonymous' :
                localStorage.getItem('anonymous_id') ? 'anonymous' : 'logout',
            vanishing_messages: [],
            alert_messages: []
        }
    }

    componentDidMount() {
        // The user should always have an anonymous ID saved in localStorage
        // The first time they login, ask the server to grant the user a new
        // well-formed anonymous ID
        if(!localStorage.getItem('anonymous_id')) {
            this.getAnonymous();
        }
    }

    anonymous() {
        if(this.state.anonymous_id) {
            return this.setLoginStatus('anonymous');
        }
        this.alert('Having difficulty communicating with the server ... please wait a few seconds and try again');
    }

    login(username: string, password: string, remember: boolean) {
        if(remember) {
            localStorage.setItem('username', username);
        } else {
            localStorage.removeItem('username');
        }
        return this.getAuthToken(username, password);
    }

    logout() {
        this.clearAccessToken();
        this.setLoginStatus('logout');
        this.alert(`Logged out`);
    }

    setAnonymousId(anonymous_id:AuthState['anonymous_id']) {
        localStorage.setItem('anonymous_id', anonymous_id || '');
        this.setState({ anonymous_id: anonymous_id });
    }
    
    setAccessToken(accessToken:AuthState['accessToken']) {
        sessionStorage.setItem('accessToken', accessToken || '');
        this.setState({ accessToken: accessToken });
    }

    clearAccessToken() {
        sessionStorage.removeItem('accessToken');
        this.setState({ accessToken: null });
    }

    setLoginStatus(loginStatus:AuthState['loginStatus']) {
        sessionStorage.setItem('loginStatus', loginStatus);
        this.setState({ loginStatus: loginStatus });
    }

    abortController = new AbortController();

    componentWillUnmount() {
        this.abortController.abort();
    }

    getAnonymous() {
        const node_env = process.env.NODE_ENV || 'development';
        const service_desc = (config as any).services[`dialogue@${node_env}`]
        if(!service_desc) {
            console.error(`config.services.dialogue@${node_env} is undefined`);
        }
        const protocol = process.env.API_PROTOCOL || service_desc.protocol || 'https';
        const host = process.env.API_HOST || service_desc.host || 'localhost';
        const path = process.env.API_PATH || service_desc.path || '/';
        const endpoint = `${protocol}://${host}${path}account/anonymous`;
        fetch(endpoint, { 
          signal: this.abortController.signal,
          mode: "cors",
          method: "POST",
          cache: "no-cache",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ 
              ... node_env !== 'production' && { prefix: node_env }
          })
        }).then(res => {
          return res.json();
        }).then(reply => {
            if(reply.anonymous_id) {
                this.setAnonymousId(reply.anonymous_id);
                this.setLoginStatus('anonymous');
            }
        }).catch(error => {
            if(error.name == 'AbortError') 
                return;
            this.alert('Interrupted communication with the server while requesting a new Anonymous ID')
        });
    }

    getAuthToken(username: string, password: string) {
        const node_env = process.env.NODE_ENV || 'development';
        const service_desc = (<any>config).services[`dialogue@${node_env}`]
        if(!service_desc) {
            console.error(`config.services.dialogue@${node_env} is undefined`);
        }
        const protocol = process.env.API_PROTOCOL || service_desc.protocol || 'https';
        const host = process.env.API_HOST || service_desc.host || 'localhost';
        const path = process.env.API_PATH || service_desc.path || '/';
        const endpoint = `${protocol}://${host}${path}account/login`;
        fetch(endpoint, { 
          signal: this.abortController.signal,
          mode: "cors",
          method: "POST",
          cache: "no-cache",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ username, password }),
        }).then(res => {
          return res.json();
        }).then(reply => {
            if(reply.access_token) {
                this.setAccessToken(reply.access_token);
                this.setLoginStatus('login');
                this.alert(`Welcome, ${username}`);
            } else {
                this.clearAccessToken();
                this.setLoginStatus('logout');
                this.alert(`Login attempt failed for ${username}: ${reply}`);
            }
        }).catch(error => {
            if(error.name == 'AbortError') 
                return;
            this.clearAccessToken();
            this.setLoginStatus('logout');
            this.alert(`Login attempt failed for ${username}: ${error}`);
        });
    }

    alert(message:string) {
        this.setState({alert_messages: this.state.alert_messages.concat([ message ])});
        new Promise(r => setTimeout(r, 2000)).then(() => {
            this.setState({
                alert_messages: this.state.alert_messages.filter( (alert_message:string) => alert_message !== message ),
                vanishing_messages: this.state.vanishing_messages.concat([message])
            });
            return new Promise(r => setTimeout(r, 2000)).then(() => {
                this.setState({
                    vanishing_messages: this.state.vanishing_messages.filter( (alert_message:string) => alert_message !== message )
                });
                    
            });
        }).catch(error => {
            throw error;
        })
    }

    render() {
        return React.createElement(AuthContext.Provider, {
            value: {
                alert: this.props.alert || this.alert.bind(this),
                anonymous: this.props.anonymous || this.anonymous.bind(this),
                login: this.props.login || this.login.bind(this),
                logout: this.props.logout || this.logout.bind(this),
                ...this.state
            }
        }, [
            ...this.state.alert_messages.map( (alert_message:string) => React.createElement(Alert, {
                banner: true,
                message: alert_message
            })),
            this.props.children
        ]);
    }
}
