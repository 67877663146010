import { Button, Comment, Divider, Drawer, Input, Popover, Row } from 'antd';
import React from 'react';
import { CommentOutlined } from '@ant-design/icons';
import moment from 'moment';
import { DialogueContext } from './DialogueService';

const { createContext } = React;
  
export interface CommentState {
    who:string|null,
    when:any,
    what:any[]|null, 
    body:string|null,
    mode:'read'|'edit'|'submitted'
};

export interface CommentCallbacks {
    open:any|null,
    close:any|null,
    add:any|null,
    submit:any|null,
    cancel:any|null,
    stack: any[]|null,
    captions: { cue:any, label:any } | null,
    oldComments: CommentState[] | null,
    newComment: CommentState | null
}

export const CommentContext:React.Context<CommentCallbacks> = createContext<CommentCallbacks>({
    open: null,
    close: null,
    add: null,
    submit: null,
    cancel: null,
    stack: null,
    captions: null,
    oldComments: null,
    newComment: null
});

export class CommentProvider extends React.Component<{}, {
    stack:any[]|null,
    captions: { cue:any, label:any }|null,
    oldComments: CommentState[] | null,
    newComment: CommentState | null
}> {
    static contextType = DialogueContext;
    open(stack:any[], captions: { cue:any, label:any } ) {
        this.setState({stack, captions});
    }

    close() {
        this.setState({stack: null, captions:null});
    }

    add() {
        const now = moment();
        this.setState({ newComment: {
            who: null,
            when: now,
            what: this.state.stack,
            body:null,
            mode:'edit'
        } });
    }

    submit(isPublic:boolean, newComment:any) {
        return this.context.comment(isPublic, newComment);
    }

    cancel() {
        this.setState({ newComment: null });
    }

    render() {
        return React.createElement(CommentContext.Provider, {
            value: {
                open: this.open.bind(this),
                close: this.close.bind(this),
                add: this.add.bind(this),
                submit: this.submit.bind(this),
                cancel: this.cancel.bind(this),
                ...this.state
            }
        }, this.props.children);
    }
}

export class CommentDrawer extends React.Component<{}, {}> {
    static contextType = CommentContext;

    add() {
        this.context.add();
    }

    cancel() {
        this.context.close();
    }

    render() {
        const cue = this.context.captions
        ? React.createElement('em', { key: 1 }, [ this.context.captions.cue ])
        : '';
        const divider1 = React.createElement(Divider, {
            key: 2,
            orientation: 'left',
        }, [ 'Comments:' ]);
        const oldComments = !this.context.oldComments 
        ? [] 
        : this.context.oldComments.map((comment:CommentState,index:number):any => 
            React.createElement(CommentEntry, {
                key: index + 2,
                ...comment
            })
        );
        const newComment = !this.context.newComment
        ? null
        : React.createElement(CommentEntry, {
            key: 'newComment',
            ...this.context.newComment
        });
        const addButton = React.createElement(Button, {
            key: 'addButton',
            onClick: this.add.bind(this),
        }, [
            'Add a Comment'
        ]);
        return React.createElement( Drawer, {
            title: React.createElement('div', {},
                "Discussion: ",
                this.context.captions 
                ? this.context.captions.label 
                : 'Loading ...'),
            placement: "right",
            closable: false,
            onClose: this.cancel.bind(this),
            visible: !!this.context.stack
        }, [ cue, divider1, ...oldComments, newComment || addButton  ]);
    }
}

export class CommentIcon extends React.Component<{
    stack:any[],
    captions: { label:any, cue:any } | null
}, {
    who: string|null,
    when: any|null,
    what: any|null,
    body: string|null,
    mode: 'hidden'|'edit'|'submitted'
}> {
    static contextType = CommentContext;

    constructor(props:any) {
        super(props)
        this.state = {
            who: null,
            when: null,
            what: null,
            body: null,
            mode: 'hidden'
        };
    }

    onVisibleChange(visible:boolean) {
        if(visible) {
            const now = moment();
            this.setState({
                what: this.props.stack,
                when: now,
                body: '',
                mode: 'edit'
            })
        } else {
            this.setState({
                who: null,
                when: null,
                what: null,
                body: null,
                mode: 'hidden'
            })
        }
    }

    body(event:any) {
        this.setState( { body: event.target.value });
    }

    add(isPublic:boolean) {
        if(this.state.body) {
            this.setState({ mode: 'submitted' });
            return this.context.submit(isPublic, this.state).then( () => {
                this.setState({
                    who: null,
                    when: null,
                    what: null,
                    body: null,
                    mode: 'hidden'
                });
                return(true);
            }).catch((error:any) => {
                throw error;
            });
        } else {
            return Promise.resolve(false);
        }
    }

    render() {
        const icon = React.createElement(CommentOutlined, {
            key: 'icon',
            onClick: this.onVisibleChange.bind(this, this.state.mode === 'hidden')
        });
        const input = React.createElement(Input, {
            key: 1,
            ...this.state.body && { defaultValue: this.state.body },
            onChange: this.body.bind(this)
        });
        const button1 =
            React.createElement(Row, { key: 'button1-row' }, [
                React.createElement(Button, {
                    key: 'button1',
                    onClick: this.add.bind(this, true),
                    disabled: this.state.mode === 'submitted'
                }, [
                    this.state.mode === 'submitted'
                    ? 'Submitted ...'
                    : 'Add a Public Comment'
                ])
            ]);
        const button2 = 
            React.createElement(Row, { key: 'button2-row' }, [
                React.createElement(Button, {
                    key: 'button2',
                    onClick: this.add.bind(this, false)
                }, [
                    this.state.mode === 'submitted'
                    ? 'Submitted ...'
                    : 'Add a Private Comment'
                ])
            ]);
        const newComment = React.createElement( Popover, {
            key: 'newComment',
            placement: "topRight",
            visible: this.state.mode !== 'hidden',
            content: [ input, button1, button2 ]
        }, [ icon ]);
        return newComment;
    }
}

export class CommentEntry extends React.Component<{ 
    key:number,
    dbid:any|null, 
    who:string|null, 
    when:any, 
    body:string,
    mode:'read'|'edit'|'submitted'
}, {
    dbid:any|null, 
    who:string|null, 
    when:any, 
    body:string,
}> {
    static contextType = CommentContext;

    constructor(props:any) {
        super(props);
        this.state = { ...this.props };
    }
    
    onChange(event:any) {
        this.setState({ body: event.target.value });
    }

    submit() {
        this.context.submit();
    }

    render() {
        if(this.props.mode === 'read') {
            return React.createElement(Comment, {
                key: this.props.key,
                author: this.state.who,
                datetime: this.state.when,
                content: this.state.body
            });
        } else {
            const editMode = this.props.mode === 'edit';
            const input = React.createElement(Input, {
                key: 1,
                defaultValue: this.state.body,
                onChange: this.onChange.bind(this)
            });
            const submitButton = React.createElement(Button, {
                key: 2,
                onClick: this.submit.bind(this),
                disabled: !editMode
            }, [
                editMode ? 'Add a Comment' : 'Submitted ...'
            ]);
            return React.createElement('div', {
                key: this.props.key
            }, [ input, submitButton ]);
        }
    }
}
