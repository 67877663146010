import React from 'react';
import { Steps, Carousel, Progress, Table, Button, Checkbox, Divider } from 'antd';
import { Text } from './Text';
import { Completed } from './Completed';
import { Label, Affixes } from './Conversation';
import { RenderProps } from './RenderProps';
import { Inspector } from './Inspector';
import State from './State';
import { AsLink, AsChecklist } from './NavService';
import { AsCard } from './Inspector';
import { DialogueContext } from './DialogueService';
const { Step } = Steps;

const formStyle = {
    maxWidth: '400px',
};

export class Steps2 extends React.Component<any, { fold: boolean }> {
    constructor(props: any) {
        super(props);
        this.state = { fold: true };
    }

    toggle() {
        this.setState({ fold: !this.state.fold });
    }

    render() {
        if (this.state.fold) {
            const item = this.props.stepArray[this.props.stepIndex];
            const steps = [React.createElement(Step, {
                key: this.props.stepIndex,
                title: (item.label ? item.label[this.props.lang] : null),
                subTitle: `(${this.props.stepIndex + 1}/${this.props.stepArray.length})`,
                description: (item.cue ? item.cue[this.props.lang] : null),
                status: 'process',
                ...{ progressDot: true },
            })];
            return React.createElement('div', {
                key: `steps2-toggle-control`,
                onClick: this.toggle.bind(this)
            }, [
                React.createElement(Steps, {
                    key: 'steps2-steps',
                    direction: 'vertical',
                    size: 'small',
                    style: { ...formStyle, paddingTop: '16px' }
                }, steps)
            ]);
        } else {
            const stepIndex = this.props.stepIndex;
            const steps = this.props.stepArray.map((item: any, index: number) => {
                return React.createElement(Step, {
                    key: index,
                    className: 'steps2-item',
                    title: (item.label ? item.label[this.props.lang] : null),
                    description: (item.cue ? item.cue[this.props.lang] : null),
                    status: index < stepIndex ? 'finish'
                        : index === stepIndex ? 'process'
                            : 'wait',
                    ...(index >= stepIndex) && { progressDot: true }
                });
            });
            return React.createElement('div', {
                key: 'Steps2-div',
                onClick: this.toggle.bind(this)
            }, [React.createElement(Steps, {
                direction: 'vertical',
                size: 'small',
                style: { ...formStyle, paddingTop: '16px' }
            }, steps)]);
        }
    }
}

export class Dynamic extends React.Component<RenderProps, {}> {
    selectByMode() {
        const stack = this.props.stack;
        const spec = stack[stack.length - 1];

        if (this.props.mode === 'paginate') {
            return React.createElement(AsForms, { ...this.props, key: 'dynamic-forms' });
        } else if (this.props.mode === 'rotate') {
            return React.createElement(AsCarousel, { ...this.props, key: 'dynamic-carousel' });
        } else if(this.props.mode === 'navigate') {
            return React.createElement(AsList, { ...this.props, key: 'dynamic-list' });
        } else if (this.props.mode === 'checklist') {
            return React.createElement(AsChecklist, { ...this.props, key: 'dynamic-checklist' });
        } else if (this.props.mode === 'dash') {
            return React.createElement(AsDash, { ...this.props, key: 'dynamic-dash' });
        } else if (this.props.mode === 'tabulate') {
            return React.createElement(AsTable, { ...this.props, key: 'dynamic-table' });
        } else if(spec.submit) {
            return React.createElement(AsForms, { ...this.props, key: 'dynamic-forms' });
        }
    }

    render() {
        const stack = this.props.stack;
        const spec = stack[stack.length - 1];

        if ((typeof spec === 'string' || spec instanceof String)) {
            return React.createElement(Text, {
                key: 'dynamic-text',
                spec,
                id: this.props.id,
                className: 'dynamic'
            });
        }
        if (spec === null || spec === undefined) {
            return React.createElement('div', {
                key: 'dynamic-loading',
                children: ["Loading ..."]
            });
        } else if (spec.status === 'committed') {
            return React.createElement(Completed, {
                key: 'confirmation',
                ... this.props
            });
        } else if (this.props.mode === 'link') {
            return React.createElement(AsLink, {
                key: 'dynamic-link',
                lang: this.props.lang,
                stack: this.props.stack,
                type: 'button'
            });
        } else if (spec.dialogue) {
            const content = this.selectByMode();
            if(content) {
                return content;
            }
        }
        const content = React.createElement(AsParagraphs, {
            key: 'dynamic-paragraphs',
            ...this.props,
        });
        return content;
    }
}

export class AsTable extends React.Component<RenderProps, {
    samples: any[],
    newItem?: any
}> {
    static contextType = DialogueContext;

    asColumns(lang:string, stack:any[],path:string[]): any[] {
        const spec = stack[stack.length-1];
        if(spec.dialogue) {
            return spec.dialogue.reduce((result:any[], symbol:any) => [...result, ...this.asColumns(lang, [...stack, symbol],[...path, symbol.name])], []);
        } else if(spec.properties) {
            return Object.getOwnPropertyNames(spec.properties).reduce((result:any[], propertyName:string) => [
                ...result, 
                ...this.asColumns(lang, [...stack, spec.properties[propertyName]],[...path, propertyName])
            ], []);
        } else if(spec.subject) {
            return this.asColumns(lang, [...stack, spec.subject], path);
        } else if(spec.domain) {
            return this.asColumns(lang, [...stack, spec.domain], path);            
        } else {
            const title = React.createElement(Label, { lang, stack, id: path.join('-'), classPrefix: path.join('-') });
            return [ { title, dataIndex: path.join('.'), key: path.join('-') } ];
        }
    }

    asDataItem(lang:string, stack:any[], path:string[]): any {
        const spec = stack[stack.length - 1];
        if(spec.value_after) {
            return { [path.join('.')]: React.createElement(Dynamic, { ...this.props, lang, stack })};
        } else if(spec.dialogue) {
            return spec.dialogue.reduce((result:any, symbol:any) => ({...result, ...this.asDataItem(lang, [...stack, symbol],[...path, symbol.name])}), {});
        } else if(spec.properties) {
            return spec.properties.reduce((result:any, property:any) => ({...result, ...this.asDataItem(lang, [...stack, property],[...path, property.name])}), []);
        } else if(spec.subject) {
            return this.asDataItem(lang, [...stack, spec.subject], path);
        } else if(spec.domain) {
            return this.asDataItem(lang, [...stack, spec.domain], path);            
        } else {
            return { [path.join('.')]: React.createElement(Dynamic, { ...this.props, lang, stack })};
        }
    }

    addNewItem() {
        const stack = this.props.stack;
        const spec = stack[stack.length - 1];
        this.context.statement('propose', spec.proto.qualname).then((newItem:any) => this.setState( { newItem }));
    }

    render() {
        const lang = this.props.lang;
        const stack = this.props.stack;
        const spec = stack[stack.length - 1];

        if(!!this.state && !!this.state.newItem) {
            return React.createElement(Inspector, { statement: spec.proto.qualname, id:'new', labeled: false, mode: 'wha'})
        } else {
            const columns = this.asColumns(lang, stack, []);

            const samples = !this.state ? [] : !this.state.samples ? [] : this.state.samples;
            const dataSource = samples.map((sample:any) => this.asDataItem(lang, [ sample ], []));
            return React.createElement('div', { key: this.props.id }, [
                React.createElement(Table, { dataSource, columns }),
                React.createElement(Button, { onClick: this.addNewItem.bind(this)}, [ 'Add +' ])
            ]);
        }
    }
}

export class AsList extends React.Component<RenderProps, {}> {
    render() {
        const lang = this.props.lang;
        const stack = this.props.stack;
        const spec = stack[stack.length - 1];
        const children = spec.dialogue.filter((symbol: any) => !symbol.modes.hidden).map((symbol: any, index: number) => {
            return React.createElement(AsLink, {
                lang: this.props.lang,
                stack: [...this.props.stack, symbol],
                type: 'text'
            });
        });
        return React.createElement('ul', { style: { textAlign: 'left' }}, children.map((child:any) => React.createElement('li', {}, [ child ])));
    }
}

export class AsMeter extends React.Component<RenderProps, {}> {
    render() {
        const lang = this.props.lang;
        const stack = this.props.stack;
        const spec = stack[stack.length - 1];
        const percent = Math.floor(100 * spec.domain.properties.count.value_after / spec.domain.properties.expected.value_after);
        return React.createElement(<any>Progress, {
            type: 'circle',
            percent,
            width: 80,
            trailColor: 'gray'
        })
    }
}

export class AsDash extends React.Component<RenderProps, {}> {
    render() {
        const lang = this.props.lang;
        const stack = this.props.stack;
        const spec = stack[stack.length - 1];
        const meters = spec.dialogue.filter((symbol: any) => !symbol.modes.hidden).map((symbol: any, index: number) => {
            return React.createElement(AsMeter, {
                key: 'meter',
                ...this.props,
                stack: [...this.props.stack, symbol]
            });
        });
        const labels = spec.dialogue.filter((symbol: any) => !symbol.modes.hidden).map((symbol: any, index: number) => {
            return React.createElement(Label, {
                key: 'label',
                ...this.props,
                stack: [...this.props.stack, symbol],
                classPrefix: 'dash-label'
            });
        });
        return React.createElement('table', {
            key: 'table',
            style: {
                width: '100%'
            }
        }, [
            React.createElement('tbody', { key: 'tr' }, [
                React.createElement('tr', {
                    key: 'meters'
                }, [
                    meters.map((child:any, index:number) => React.createElement('td', {
                        key: `dash-meter-${index}`
                    }, [ child ]))
                ]),
                React.createElement('tr', {
                    key: 'labels'
                }, [
                    labels.map((child:any, index:number) => React.createElement('td', {
                        key: `dash-label-${index}`
                    }, [ child ]))
                ])
            ])
        ]);
    }
}

export class AsCarousel extends React.Component<RenderProps, {}> {
    render() {
        const lang = this.props.lang;
        const stack = this.props.stack;
        const spec = stack[stack.length - 1];
        const paginate = this.props.mode === 'paginate';
        if (paginate) {
            const children = spec.dialogue.filter((symbol: any) => !symbol.modes.hidden).map((symbol: any, index: number) => {
                return React.createElement(AsCard, {
                    key: `card-${index}`,
                    mode: this.props.mode,
                    lang,
                    proposal: symbol,
                    labeled: true
                });
            });
            return React.createElement(Carousel, { autoplay: !paginate, dots: true }, children);
        } else {
            const children = spec.dialogue.filter((symbol: any) => !symbol.modes.hidden).map((symbol: any, index: number) => {
                return React.createElement(AsLink, {
                    key: `link-${index}`,
                    lang: this.props.lang,
                    stack: [...this.props.stack, symbol],
                    type: 'button'
                });
            });
            return React.createElement(Carousel, { autoplay: !paginate, dots: true }, children);
        }
    }
}


export class AsForms extends React.Component<RenderProps, {
    showInstructions: boolean
}> {
    constructor(props:any) {
        super(props);
        this.state = {
            showInstructions: false
        }
    }

    toggleInstructions(e:any) {
        this.setState({ showInstructions: e.target.checked });
    }

    render() {
        const lang = this.props.lang;
        const stack = this.props.stack;
        const spec = stack[stack.length - 1];
        const stepArray = spec.dialogue.filter((item: any) => !!item.subject && item.subject.status !== 'found');
        const hasSteps = stepArray.length > 0;
        if (hasSteps) {
            const accumulated = stepArray.reduce((accum: { stepIndex: number, found: boolean }, item: any) => {
                const increment = (
                    item.subject.status === 'committed' || item.subject.status === 'canceled' ? 1 : 0
                );
                return ({
                    stepIndex: accum.found ? accum.stepIndex : accum.stepIndex + increment,
                    found: accum.found || increment === 0
                });
            }, { stepIndex: 0, found: false });
            const { stepIndex } = accumulated;
            if (stepIndex === stepArray.length) {
                return React.createElement(Completed, {
                    key: 'confirmaton',
                    ...this.props
                });
            } else {
                console.log(`DEBUG: AsForms(${stepArray[stepIndex].name}) is rendering`)
                const steps = React.createElement(Steps2, {
                    key: 'steps2',
                    lang,
                    stepIndex,
                    stepArray
                });
                spec.step = stepArray[stepIndex];
                const showInstructions = React.createElement(Checkbox, {
                    defaultChecked: this.state.showInstructions,
                    onChange: this.toggleInstructions.bind(this)
                }, [
                    "Show Instructions"
                ]);
                const showInstructions_p = React.createElement('p', {}, showInstructions);
                const contents = 
                    this.state.showInstructions
                    ? React.createElement(State.AsFormSections, {
                        ...this.props,
                        key: 'state-body',
                        stack: [...stack, stepArray[stepIndex], stepArray[stepIndex].subject],
                        mode: stepArray[stepIndex].iterator_mode
                    })
                    : React.createElement(State.AsFormItems, {
                        ...this.props,
                        key: 'state-body',
                        stack: [...stack, stepArray[stepIndex], stepArray[stepIndex].subject],
                        mode: stepArray[stepIndex].iterator_mode
                    });
                const affixed = !this.state.showInstructions ? contents :
                    React.createElement(Affixes, {
                        ...this.props,
                        key: 'affixes',
                        id: 'affixes'
                    }, [ contents ])
                const actions = React.createElement(State.Actions, {
                        key: 'state-actions',
                        ...this.props,
                        stack: [...stack, stepArray[stepIndex], stepArray[stepIndex].subject],
                        mode: stepArray[stepIndex].iterator_mode
                    });
                return [steps, showInstructions_p, affixed, actions];
            }
        } else {
            const showInstructions = React.createElement(Checkbox, {
                onChange: this.toggleInstructions.bind(this)}, [
                    "Show Instructions"
            ]);
            const showInstructions_p = React.createElement('p', { key: 'show-instructions' }, showInstructions);
            const contents = this.state.showInstructions
                ? React.createElement(State.AsFormSections, {
                    ...this.props,
                    key: 'state-body',
                    stack: [...stack, spec],
                    mode: spec.iterator_mode
                })
                : React.createElement(State.AsFormItems, {
                    ...this.props,
                    key: 'state-body',
                    stack: [...stack, spec],
                    mode: spec.iterator_mode
                });
            const affixed = !this.state.showInstructions ? contents :
                React.createElement(Affixes, {
                    ...this.props,
                    key: 'affixes',
                    id: 'affixes'
                }, [ contents ])
            const actions = React.createElement(State.Actions, {
                key: 'state-actions',
                ...this.props,
                stack: [...stack, spec],
                mode: spec.iterator_mode
            });
            return [ showInstructions_p, affixed, actions ];
        }
    }
}

export class SectionHeader extends React.Component<RenderProps, {}> {
    render() {
        const title = React.createElement(Label, {
            ...this.props,
            classPrefix: 'section-title'
        });
        const divider = React.createElement(Divider, { orientation: 'left' }, [title]);
        return [divider, this.props.children];
    }
}

export class AsParagraphs extends React.Component<RenderProps, {}> {
    render() {
        const lang = this.props.lang;
        const stack = this.props.stack;
        const spec = stack[stack.length - 1];
        const contents = 
            React.createElement(State.AsFormSections, {
                ...this.props,
                key: 'state-body',
                stack: [...stack, spec],
                mode: spec.iterator_mode,
                container: 'p'
            });
        const affixes =
            React.createElement(Affixes, {
                ...this.props,
                key: 'state-paragraph-affixes'
            }, contents);
        const actions = React.createElement(State.Actions, {
            ...this.props,
            key: 'state-actions',
            stack: [...stack, spec],
            mode: spec.iterator_mode
        });
        return React.createElement('div', { key: this.props.id }, [ affixes, actions ]);
    }
}