import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { NavServiceWithRouter } from './NavService';
import { AuthProvider } from "./AuthService";
import { DialogueProvider } from './DialogueService';
import { Session } from "./Session";

import './App.css';
import 'antd/dist/antd.css'; 


class App extends React.Component {
  componentDidMount() {
    document.title = 'Smart Citizens App';
  }

  render() {

    return (
      <div className="App">
        <BrowserRouter>
        <AuthProvider>
          <Session>
            <DialogueProvider>
              <NavServiceWithRouter/>
            </DialogueProvider>
          </Session>
        </AuthProvider>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
