import React from 'react';

import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { Menu, Button } from 'antd';
import { DialogueContext } from './DialogueService';
import { NavContext } from './NavContext';
import { Label, Affix } from './Conversation';
import { Inspector } from './Inspector';
import { RenderProps } from './RenderProps';

export interface NavCallbacks {
    selectDefinition?: any,
    selectRoute?: any,
    lookupRoute?: any,
    getReturnState?: any
}

export interface NavState {
    definitionNames: any[],
    definitionByPurename: any,
    routeByPurename: any,
    selectedPath?: any,
    returnState?: any
}

interface NavProps {
    location: any,
    history: any,
    match: any
};

export class NavService extends React.Component<NavProps, NavState> {
    static contextType = DialogueContext;

    appDefn = { "@class": "Reference", qualname: "semantic.projects.covid19.apps.before_we_meet" }

    constructor(props: any) {
        super(props);
        console.log(`DEBUG: in NavService.constructor resetting returnState`);
        this.state = props.history.state || {
            definitionNames: [],
            definitionByPurename: {},
            routeByPurename: {},
            selectedPath: props.location.pathname
        };
    }

    componentDidMount() {
        this.getDefnNames();
        this.getRouteDefn(this.appDefn, []);
    }

    getDefnNames() {
        this.context.catalog().then( (names:string[]) => {
            console.log(`DEBUG: in NavService.getDefnNames, names list is ${JSON.stringify(names)}`);
            this.setState({ definitionNames: names});
        });
    }

    getRouteDefn(defn: any, path: string[]) {
        const route = ["", ...path].join('/');
        if (!this.state.routeByPurename[defn.qualname]) {
            const routeByPurename = { ...this.state.routeByPurename };
            routeByPurename[defn.qualname] = route;
            this.setState({ routeByPurename });
        }
        if (!this.state.definitionByPurename[defn.qualname]) {
            this.context.describe(defn, null).then((defnList: any) => {
                const definitionByPurename = { ...this.state.definitionByPurename };
                definitionByPurename[defn.qualname] = defnList[0];
                this.setState({ definitionByPurename });
                if (defnList[0].dialogue) {
                    defnList[0].dialogue.filter((symbol: any) => symbol.subject).forEach((symbol: any) => {
                        this.getRouteDefn(symbol.subject, [...path, symbol.name]);
                    });
                }
            }).catch((error: any) => {
                console.error(error);
                return Promise.reject(error);
            });
        }
    }

    renderMenu(defn: any | null, mode: string, path: string[], key: string, parentTitle: any | null): any {
        if (!defn) {
            return this.renderMenu(this.appDefn, mode, [], key, null);
        } else if (!this.state.definitionByPurename[defn.qualname]) {
            return [];
        } else {
            const loaded = this.state.definitionByPurename[defn.qualname];
            const classPrefix = path.join('-');
            const route = ["", ...path].join('/');

            const title = parentTitle || React.createElement(Label, { stack: [loaded], lang: 'en_US', key, id: key, classPrefix });

            if (mode == 'navigate') {
                const symbols = !loaded.dialogue ? [] : loaded.dialogue.filter((symbol: any) => symbol.subject);
                const children = symbols.map((symbol: any) => {
                    const childTitle = symbol.label && React.createElement(Label, { stack: [symbol], lang: 'en_US', key, id: key, classPrefix });
                    return this.renderMenu(symbol.subject, symbol.iterator_mode, [...path, symbol.name], symbol.name, childTitle);
                });
                if (path.length > 0) {
                    return React.createElement(Menu.SubMenu, { 
                        title, 
                        key,
                        onTitleClick: this.selectRoute.bind(this, route)
                    }, children);
                } else {
                    return children;
                }
            } else {
                return React.createElement(Menu.Item, {
                    key: route,
                    className: `menu-${classPrefix}`,
                }, [title]);
            }
        }
        return [];
    }

    renderRoutes(defn: any | null, mode: string, path: string[], key: string): any {
        if (!defn) {
            const defnRoutes = (this.state && this.state.definitionNames ? this.state.definitionNames : []).map((defnName:string, index:number) => {
                const key = index;
                const from = ["", ...path, ...defnName.split('.')].join('/');
                //console.log(`DEBUG: adding route ${from} to inspect ${defnName}`);
                return React.createElement(Route, { key, path: from }, [
                    React.createElement(Inspector, { key: 1, statement: defnName, mode: 'elaborate' })
                ]);
            });
            return [
                ...defnRoutes,
                ...this.renderRoutes(this.appDefn, mode, [], key)
            ];
        } else if (this.state.definitionByPurename[defn.qualname]) {
            const loaded = this.state.definitionByPurename[defn.qualname];
            const route = ["", ...path].join('/');
            //console.log(`DEBUG: adding route ${route} to inspect ${loaded.qualname}`);
            const me = React.createElement(Route, { key, path: route }, [
                React.createElement(Inspector, {
                    key: 1,
                    id: 1,
                    statement: loaded.qualname,
                    labeled: undefined,// path.length < 2,
                    mode
                })
            ]);
            const symbols = !loaded.dialogue ? [] : loaded.dialogue.filter((symbol: any) => symbol.subject);
            const children = symbols.reduce((accum: any[], symbol: any) => {
                return [...accum, ...this.renderRoutes(symbol.subject, symbol.iterator_mode, [...path, symbol.name], symbol.name)];
            }, []);
            return [ ...children, me ];
        }
        return [];
    }

    onSelect(param: any) {
        this.selectRoute(param.key);
    }
        
    selectRoute(route: string) {
        this.props.history.push({ ...this.state, pathname: route }, route, route);
    }

    selectDefinition(selected_qualname: string, returnState?: any) {
        const route = ['', ...selected_qualname.split('.')].join('/');
        console.log(`DEBUG: in selectDefinition, route is ${route} and returnState is ${JSON.stringify(returnState)}`);
        this.props.history.push({ returnState, pathname: route }, route, route);
        this.setState({ returnState });
    }

    lookupRoute(purename: string) {
        return this.state.routeByPurename[purename];
    }
    
    getReturnState():any|undefined {
        if(!this.state.returnState) {
            return Promise.resolve(undefined);
        }
        return this.context.statement('revise', this.state.returnState.proto.qualname, this.state.returnState);
    }

    render() {
        const main = React.createElement(Route, { key: 'home', path: '/', exact: true }, [
            React.createElement(Redirect, { key: 'home-redirect', to: '/score' })
        ]);
        const routes = this.renderRoutes(null, 'navigate', [], "1");
        const menuItems = [this.renderMenu(null, 'navigate', [], "1", null)];
        const contents = [
            React.createElement(Menu, {
                key: 'menu',
                mode: 'horizontal',
                onSelect: this.onSelect.bind(this),
                selectedKeys: !this.state.selectedPath ? [] : [this.state.selectedPath]
            }, menuItems),
            React.createElement(Switch, {
                key: 'switch'
            }, [
                main,
                ...routes
            ])
        ];
        return React.createElement(NavContext.Provider, {
            value: {
                selectDefinition: this.selectDefinition.bind(this),
                selectRoute: this.selectRoute.bind(this),
                lookupRoute: this.lookupRoute.bind(this),
                getReturnState: this.getReturnState.bind(this)
            }
        }, contents);
    }
}

export const NavServiceWithRouter = withRouter(NavService);

export class AsLink extends React.Component<{ lang: string, stack: any[], type: 'button'|'text', checklist?: any, hidden?: Boolean }, {}> {
    static contextType = NavContext;

    getSpec(index: number): any {
        if (this.props.stack[index].link) {
            return this.props.stack[index];
        }

        if (index > 0 && this.props.stack[index - 1]['@class'] === 'Symbol.State') {
            const label = this.getSpec(index - 1);
            if (label) {
                return label;
            }
        }

        return this.props.stack[index];
    }

    onClick() {
        const spec = this.getSpec(this.props.stack.length - 1);
        const qualname = (spec.link && spec.link.qualname)
            || (spec.subject && spec.subject.qualname)
            || (spec.subject && spec.subject.proto && spec.subject.proto.qualname)
            || (spec.qualname) || (spec.proto && spec.proto.qualname);

        this.context.selectDefinition(qualname, this.props.checklist);
    }

    render() {
        if(this.props.hidden) {
            return [];
        }

        const spec = this.props.stack[this.props.stack.length - 1];
        const stack = spec.subject ? [...this.props.stack, spec.subject] : this.props.stack;
        const label = React.createElement(Label, {
            lang: this.props.lang,
            id: 1,
            key: 1,
            stack,
            classPrefix: 'as-link'
        });
        const div = React.createElement(this.props.type === 'button' ? Button : 'a', {
            style: { width: '100%' },
            onClick: this.onClick.bind(this)
        }, [label]);
        return div;
    }
}

export class AsChecklist extends React.Component<RenderProps, {}> {
    static contextType = NavContext;
    
    render() {
        const lang = this.props.lang;
        const stack = this.props.stack;
        const spec = stack[stack.length - 1];
        const peers = spec.dialogue.filter((symbol: any) => !symbol.modes.hidden && symbol.subject.status !== 'hidden');
        const children = peers.map((symbol: any, tr_index: number) => {
            const isDone = symbol.subject.status === 'done';
            const isDue = symbol.subject.status === 'due';
            const icon = React.createElement('div', { 
                key: 'icon',
                dangerouslySetInnerHTML: { __html: isDone ? "&#9989;" : "&#128276;" } 
            });
            const indicators = [
                React.createElement(Affix, {
                    key: 'affix',
                    ...this.props,
                    stack: [ ...this.props.stack, symbol, symbol.subject],
                    propertyName: 'cue',
                    classPrefix: 'checklist-step-cue'
                }),
                icon,
                React.createElement(AsLink, {
                    key: 'link',
                    lang: this.props.lang,
                    stack: [ ...this.props.stack, symbol, symbol.subject],
                    checklist: spec,
                    type: 'button',
                    hidden: !isDue
                })
            ]
            return React.createElement('tr', {
                key: tr_index
             }, indicators.map((indicator:any, td_index:number) => 
                React.createElement('td', { 
                    key: td_index,
                    style: { padding: '0 1em 1em 1em' }
                }, [ indicator ])
            ));
        });
        return React.createElement('table', { 
            key: 'checklist-table',
        }, [
            React.createElement('tbody', {
                key: 'tbody'
            }, children)
        ]);
    }
}
