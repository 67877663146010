import { Card, Form, Input, Button, Checkbox } from 'antd';
import React from 'react';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { AuthContext } from './AuthService';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};
const cardStyle = {
  maxWidth: '400px',
  display: 'inline-block'
};

const formStyle = {
  maxWidth: '400px',
};

const centerStyle = {
  width: '100%',
  textAlign: 'center',
  display: 'block'
};

interface LoginState {
  remember: boolean,
  username: string|null,
  password: string|null
};

function return_url() {
  if(sessionStorage.getItem('return_location') !== null) {
    window.location= JSON.parse(sessionStorage.getItem('return_location') || ""); 
  }
  sessionStorage.removeItem('return_location');
}

/* export class Session extends React.Component {
  static contextType = AuthContext;
  render() {
    const abbrev = !this.context.username 
    ? "Guest" 
    : this.context.username.length > 12 
    ? this.context.username.substring(0, 12) + "..." 
    : this.context.username;
    return React.createElement('div', { style: centerStyle }, [
      React.createElement('div', { style: { ...formStyle, width: '100%'} }, [
        React.createElement(Row, {}, [
          React.createElement(Col, { span: 17, style: { marginTop: '8px' } }, [
            `Welcome, ${abbrev}`
          ]),
          React.createElement(Col, { span: 1 }, []),
          React.createElement(Col, { span: 6 }, [
            React.createElement(Button, {
              style: buttonStyle,
              onClick: ( event ) => {
                sessionStorage.setItem('return_location', JSON.stringify(window.location));
                if(this.context.accessToken) {
                  window.location.pathname = '/account/logout';
                } else {
                  window.location.pathname = '/account/login';
                }
              }
            }, [this.context.accessToken ? 'Logout' : 'Login'])
          ])
        ])
      ])
    ]);
  }
}
 */
export class Login extends React.Component<{}, LoginState> {
  static contextType = AuthContext;
  constructor(props:any) {
    super(props);
    this.state = {
      remember: !!localStorage.getItem('username'),
      username: localStorage.getItem('username') || null,
      password: null
    }
  }

  onLogin() {
    this.context.login(this.state.username, this.state.password, this.state.remember);
    return_url();
  }

  onAnonymous() {
    this.context.anonymous();
    return_url();
  }

  onCancel() {
    return_url();
  }

  render(): React.ReactElement {
    return (
      React.createElement('div', {
        style: {
          textAlign: "center"
        }
      } , [
        React.createElement(Card, { 
          style: cardStyle,
          key: 1,
          title: React.createElement('span', { }, ["Login"]),
          children: [
            React.createElement(Form, { 
              ...layout,
              name: "basic",
              key: 1,
              children: [
                React.createElement(Form.Item, {
                  className: 'login-email-label',
                  label: "Email",
                  name: "email",
                  key: 1,
                  children: [
                    React.createElement(Input, {
                      className: 'login-email-input',
                      key: 1,
                      ...this.state.username && { defaultValue: this.state.username },
                      onChange: (event: any) => this.setState({ username: event.target.value })
                    })
                  ]
                }),
                React.createElement(Form.Item, {
                  label: "Password",
                  name: "password",
                  key: 2,
                  children: [
                    React.createElement(Input.Password, {
                      className: 'login-password-input',
                      key: 1,
                      onChange: (event: any) => this.setState({ password: event.target.value })
                    })
                  ]
                }),
                React.createElement(Form.Item, {
                  ...tailLayout,
                  key: 3,
                  name: "remember",
                  valuePropName: "checked",
                  children: [
                    React.createElement(Checkbox, {
                      key: 1,
                      defaultChecked: this.state.remember,
                      onChange: (event: CheckboxChangeEvent) => this.setState({ remember: event.target.checked })
                    }, [ "Remember Me" ])
                  ]
                }),
                React.createElement(Form.Item, {
                  ...tailLayout,
                  key: 4,
                  children: [
                    React.createElement(Button, {
                      key:1 ,
                      className: 'login-login-button',
                      type: "primary",
                      htmlType: "submit",
                      onClick: this.onLogin.bind(this)
                    }, [ `Login as ${this.state.username || '...'}` ])
                  ]
                }),
                React.createElement(Form.Item, {
                  ...tailLayout,
                  key: 5,
                  children: [
                    React.createElement(Button, {
                      key:1 ,
                      className: 'login-anonymous-button',
                      type: "primary",
                      htmlType: "submit",
                      onClick: this.onAnonymous.bind(this)
                    }, [ `Continue Anonymously` ])
                  ]
                }),
                ...!sessionStorage.getItem('return_url') ? [] :
                  [ React.createElement(Form.Item, {
                    ...tailLayout,
                    key: 6,
                    children: [
                      React.createElement(Button, {
                        key:1 ,
                        className: 'login-cancel-button',
                        type: "primary",
                        htmlType: "button",
                        onClick: this.onCancel.bind(this)
                      }, [ "Cancel" ])
                    ]
                  }) ]
              ]
            })
          ]
        })
      ])
    );
  }
}

export class Logout extends React.Component<{}> {
  static contextType = AuthContext;
  onLogout() {
    this.context.logout();
    return_url();
  }

  onCancel() {
    return_url();
  }

  render(): React.ReactElement {
    return (
      React.createElement('div', {
        style: {
          textAlign: "center"
        }
      } , [
        React.createElement(Card, { 
          style: cardStyle,
          key: 1,
          title: React.createElement('span', { }, ["Logout"]),
          children: [
            React.createElement(Form, { 
              ...layout,
              name: "basic",
              key: 1,
              children: [
                React.createElement('div', {}, ["Logout - are you sure?" ] ),
                React.createElement(Form.Item, {
                  ...tailLayout,
                  key: 1,
                  children: [
                    React.createElement(Button, {
                      key:1 ,
                      className: 'logout-cancel-button',
                      type: "primary",
                      htmlType: "button",
                      onClick: this.onCancel.bind(this)
                    }, [ "Cancel" ]),
                    React.createElement(Button, {
                      key:1 ,
                      className: 'logout-logout-button',
                      type: "primary",
                      htmlType: "submit",
                      onClick: this.onLogout.bind(this)
                    }, [ "Logout" ])
                  ]
                })
              ]
            })
          ]
        })
      ])
    );
  }
}

export class Session extends React.Component<{}, {}> {
  static contextType = AuthContext;

  render() {
    if(this.context.loginStatus === 'logout') {
      return React.createElement(Login,{});
    } else if(this.context.loginStatus === 'anonymous' && !this.context.anonymous_id) {
      return React.createElement('div', {}, ["Welcome to the Smart Citizens App.","You do not need to login or create a userid to use this. An anonymous ID will be generated for you."]);
    } else {
      return this.props.children;
    }
  }
}
