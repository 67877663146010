class Model {
    fromJSON(obj:any|null):any|null {
        if(obj === null || obj === undefined) {
            return obj;
        } else if(Array.isArray(obj)) {
            return obj.map((item:any) => this.fromJSON(item));
        } else if(obj['@class'] === 'Domain.Def') {
            const result = new Domain();
            result.fromJSON(obj, this);
            return result;
        } else {
            return obj;
        }
    }
}
export class Domain {
    qualname: string|null;
    type_: string|null;
    step: Number|null;
    values: Array<any>;
    default: any|null;
    properties: Array<any>;

    constructor() {
        this.qualname = null;
        this.type_ = null;
        this.step = null;
        this.values = new Array<any>();
        this.default = null;
        this.properties = new Array<any>();
    }

    fromJSON(obj:any, model:Model):void {
        this.qualname = obj.qualname;
        this.type_ = model.fromJSON(obj.type_);
        this.step = model.fromJSON(obj.step);
        this.values = model.fromJSON(obj.values);
        this.default = model.fromJSON(obj.default);
        this.properties = model.fromJSON(obj.properties);
    }
}

const _theModel:Model = new Model();

export const theModel = () => {
    return _theModel;
}