import React from 'react';
import { Progress as AntdProgress } from 'antd';
import { DomainProps } from './Domain';

export class Progress extends React.Component<DomainProps, { }> {
    static canRender(spec:any) {
        return(spec.proto.qualname === 'semantic.projects.covid19.policy.scoring.metric');
    }

    render() {
        const state = this.props.stack[this.props.stack.length - 1];
        const spec = {
            ...this.props.defn,
            ...state
        };
        return React.createElement(<any>AntdProgress, {
            key: `${spec.prop_path.path}-Checkbox`,
            type: 'circle',
            percent: Math.round((100 * spec.properties.count.value_after) / spec.properties.expected.value_after),
            trailColor: 'gray'
        });
    }
}
