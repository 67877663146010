import * as React from 'react';
import classNames from 'classnames';
import { ConfigConsumer, ConfigConsumerProps } from 'antd/lib/config-provider';
import Divider, { DividerProps } from 'antd/lib/divider';

const Divider2: React.FC<DividerProps> = props => (
  <div style={{ display: 'inline-block', width: '100%' }}>
    <div style={{ display: 'inline', width: '50%', float: 'left' }}>
      <Divider {...props} orientation='left'>{props.children ? (props.children as any[])[0] : null}</Divider>
    </div>
    <div style={{ display: 'inline', width: '50%', float: 'right' }}>
      <Divider {...props} orientation='right'>{props.children ? (props.children as any[])[1] : null}</Divider>
    </div>
  </div>
);

export default Divider2;