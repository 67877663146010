import { Divider, Input, Button, Form, Select } from 'antd';
import React from 'react';
import * as moment from 'moment';

import { RenderProps } from './RenderProps';
import { DialogueContext } from './DialogueService';

interface ReminderState {
    notify_time: moment.Moment,
    statement_defn?: String,
    notify_whom?: string,
    notify_active: boolean,
    fold: boolean
};

export class Reminder extends React.Component<RenderProps, ReminderState> {
    static contextType = DialogueContext;

    constructor(props: RenderProps) {
        super(props);
        const stack = props.stack;
        const spec = stack[stack.length - 1];

        const now = moment.default();
        now.seconds(0);
        now.milliseconds(0);
        now.subtract(now.minutes() % 15, 'minutes');
        const notify_time = moment.default(localStorage.getItem('notify_time') || now);
        const statement_defn = Reminder.findStatementDefn(stack, stack.length - 1);
        const notify_whom = localStorage.getItem('notify_whom') || undefined;
        const notify_active = !!localStorage.getItem('notify_active') || !!statement_defn && !!notify_whom;

        this.state = {
            notify_time,
            statement_defn,
            notify_whom,
            notify_active: notify_active,
            fold: true
        };
    }

    static findStatementDefn(stack: any[], index:number):string|undefined {
        if(stack[index]['@class'] == 'Statement.State') {
            return stack[index].proto['qualname'];
        }
        if(index > 0) {
            return this.findStatementDefn(stack, index - 1);
        }
    }

    setNotifyWhom(event:any) {
        localStorage.setItem('notify_whom', event.target.value);
        this.setState({ notify_whom: event.target.value});
    }
    setNotifyTime(value:any) {
        const as_moment = moment.default(value)
        localStorage.setItem('notify_time', as_moment.toString());
        this.setState({ notify_time: as_moment});
    }
    setActive(notify_active:boolean) {
        localStorage.setItem('notify_active', `${notify_active}`);
        this.setState({ fold: true});
        this.setState({ notify_active });
        this.context.reminder(this.state.notify_whom, this.state.notify_time, notify_active, this.state.statement_defn);
    }
    unfold() {
        this.setState({ fold: false });
    }
    getDates() {
        var dateArray = [];
        var currentDate = moment.default();
        currentDate.startOf('day');
        var stopDate = moment.default().add(1,'days')
        stopDate.startOf('day');
        while (currentDate <= stopDate) {
            dateArray.push( moment.default(currentDate) )
            currentDate = moment.default(currentDate).add(15, 'minutes');
        }
        return dateArray;
    }    
    render() {
        const stack = this.props.stack;
        const spec = stack[stack.length - 1];
        const lang = this.props.lang || 'en_US';

        if(!spec.has_reminders) {
            return this.props.children;
        } else if(this.state.fold) {
            const message = 
                this.state.notify_active ? `Remind me to return every day at ${this.state.notify_time.format('hh:mm A')}` : "Reminders off";
            const label = 
                this.state.notify_active ? "Change..." : "Setup";

            const table =
                React.createElement('table', {
                    key: 'checklist-table'
                }, [
                    React.createElement('tbody', {
                        key: 'checkist-tbody'
                    }, [
                        React.createElement('tr', {
                            key: 'checkist-tr'
                        }, [
                            React.createElement('td', {
                                key: 'checklist-item-label',
                                style: { padding: '0 20px'}
                            }, [ message ]),
                            React.createElement('td', {
                                key: 'checklist-item-alarm-status',
                                style: { padding: '0 20px'}
                            }, [
                                React.createElement(Button, {
                                    key: 'checklist-item-start-button',
                                    onClick: this.unfold.bind(this)
                                }, [ label ])            
                            ])
                        ])
                    ])
                ]);
            const container = React.createElement(this.props.container || 'div', { key: this.props.id }, [ table ]);
            return React.createElement('div', { key: this.props.id }, [ container, this.props.children ]);
        } else {
            const divider = React.createElement(Divider, {}, ['Reminder']);
            const label = 
            React.createElement(Form.Item, { 
                label: 'What',
                name: 'what',
                children: [
                    'Remind me to return to this form each day'
                ]
            });
            const notifyTime = 
                React.createElement(Form.Item, { 
                    label: 'At',
                    name: 'at',
                    children: [
                        React.createElement(Select, { 
                            key: 'notifyTime',
                            style: { width: '100%' },
                            onChange: this.setNotifyTime.bind(this),
                            defaultValue: this.state.notify_time.toString(),
                        }, this.getDates().map((date) => {
                            return React.createElement(Select.Option, { value: date.toString(), children: [date.format('hh:mm A')]});
                        })) 
                    ]
                });
            const notifyWhom = 
                React.createElement(Form.Item, { 
                    label: 'Remind',
                    name: 'whom',
                    children: [
                        React.createElement(Input, {
                            key: 'notifyWhom',
                            ...this.state.notify_whom && { defaultValue: this.state.notify_whom },
                            onChange: this.setNotifyWhom.bind(this),
                            style: { width: '100%' },
                            placeholder: 'Phone number for SMS reminder'
                        })
                    ]
                });
            const set = React.createElement(Button, {
                key: 'active',
                onClick: this.setActive.bind(this, true)
            }, [ 'Set' ])
            const clear = React.createElement(Button, {
                key: 'clear',
                onClick: this.setActive.bind(this, false)
            }, [ 'Clear' ])
            const container = React.createElement('div', {
                key: 'row'
            }, [ divider, label, notifyTime, notifyWhom, set, clear ]);
            return container;
        }
    }
};
