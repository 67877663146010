import React from 'react';
import { NavCallbacks } from './NavService';

const { createContext } = React;

export const NavContext: React.Context<NavCallbacks> = createContext<NavCallbacks>({
    selectDefinition: undefined,
    selectRoute: undefined,
    lookupRoute: undefined,
    getReturnState: undefined
});
